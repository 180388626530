<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row g-3">
                <!-- Left Column with Tabs -->
                <div class="col-xl-8 col-lg-7 col-md-12" style="border-right:lightgray 2px solid;
    padding-right: 10px;">

                    <!-- Task Header with Custom Status Dropdown -->
                    <div class="d-flex align-items-center justify-content-start mb-3 mt-2">
                        <div class="d-inline-flex align-items-center ms-2">
                            <!-- Circle with 'P' -->
                            <span class="project-circle2">P</span>

                            <!-- Pipe separator -->
                            <span class="ms-2" style="font-size:1.5rem">|</span>

                            <!-- Avatar image, only shown if projectViewData and projectViewData.client exist -->
                            <img v-if="taskViewData && taskViewData?.project?.client?.avatar_file_name"
                                :src="`/storage/clients_avatars/${taskViewData.project.client.avatar_file_name}`"
                                style="width:10em">

                            <span>
                                <span v-if="taskViewData && taskViewData.project?.code" style="font-size:1.2rem">{{
                                    taskViewData.project.code }}</span>
                            </span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-start mb-3 mt-2">
                        <div class="d-inline-flex align-items-center ms-2">

                            <span class="project-circle">T</span>

                            <span class="project-code ms-2" v-if="taskViewData">{{ taskViewData.info?.title }}</span>

                            <!-- Custom Status Dropdown -->
                            <div class="status-dropdown ms-3">
                                <button class="status-button justify-content-center" v-if="taskViewData"
                                    :style="getStatusStyle(taskViewData.status?.id)">
                                    <i :class="getStatusIcon(taskViewData.status?.id)"
                                        v-if="canEditTask(taskViewData) || canEditAllTasks || superUser"></i>
                                    {{ getCurrentStatus(taskViewData.status?.id) }}
                                    <i class="fa fa-caret-down"
                                        v-if="canEditTask(taskViewData) || canEditAllTasks || superUser"></i>
                                </button>

                                <!-- Dropdown Content (Visible on Hover) -->
                                <div class="status-dropdown-content"
                                    v-if="canEditTask(taskViewData) || canEditAllTasks || superUser">
                                    <button v-for="status in statuses" :key="status.id"
                                        @click="selectStatus(taskViewData.info?.id, status.id)"
                                        :style="getStatusStyle(status.id)"
                                        :class="{ 'active-status': taskViewData?.status.id === status.id }">
                                        <i :class="status.icon"></i>
                                        {{ status.description }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Button Positioned Over Border -->
                        <div class="status-button-wrapper">
                            <!-- Edit Button Info-->
                            <button class="btn btn-dark"
                                v-if="!isEditingInfo && activeTab === 'info' && taskViewData && (canEditTask(taskViewData) || canEditAllTasks || superUser)"
                                @click="editTask">
                                <img src="../assets/images/edit.png" alt="Edit" title="Edit" class="menu-img"
                                    style="width:2em">
                            </button>

                            <!-- Save and Cancel Buttons -->
                            <div v-if="isEditingInfo && activeTab === 'info'" class="editing-actions">
                                <!-- Save Button -->
                                <button class="btn btn-secondary mb-3" @click="saveInfo">
                                    <img src="../assets/images/save.png" alt="Save" title="Save" class="menu-img"
                                        style="width:2em">
                                </button>

                                <!-- Cancel Button -->
                                <button class="btn btn-danger mt-2" @click="cancelEditing">
                                    <img src="../assets/images/cancel.png" alt="Cancel" title="Cancel" class="menu-img"
                                        style="width:2em">
                                </button>
                            </div>


                            <!-- Edit Button Manager-->
                            <button class="btn btn-dark"
                                v-if="!isEditingManagersBrief && activeTab === 'managerBrief' && taskViewData && (taskViewData.info.managerId == currentUserInfo?.id || superUser || canEditAllTasks)"
                                @click="editTaskManager">
                                <img src="../assets/images/edit.png" alt="Edit" title="Edit" class="menu-img"
                                    style="width:2em">
                            </button>

                            <!-- Save and Cancel Buttons -->
                            <div v-if="isEditingManagersBrief && activeTab === 'managerBrief'" class="editing-actions">
                                <!-- Save Button -->
                                <button class="btn btn-secondary mb-3" @click="saveManager">
                                    <img src="../assets/images/save.png" alt="Save" title="Save" class="menu-img"
                                        style="width:2em">
                                </button>

                                <!-- Cancel Button -->
                                <button class="btn btn-danger mt-2" @click="cancelEditingManager">
                                    <img src="../assets/images/cancel.png" alt="Cancel" title="Cancel" class="menu-img"
                                        style="width:2em">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-start mb-1 ms-2">

                        <router-link :to="{ path: 'project', query: { id: taskViewData.project?.id } }"
                            v-if="taskViewData">
                            <span class="breadcrumb-links">{{ taskViewData.project?.code }}</span>
                        </router-link> <span class="ms-2 me-2">/</span><router-link
                            :to="{ path: 'task', query: { id: taskViewData.info?.id } }" v-if="taskViewData">
                            <span class="breadcrumb-links">{{ taskViewData.info?.title }}</span>
                        </router-link>
                    </div>
                    <!-- Tabs -->
                    <ul class="nav nav-tabs mb-3" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'info' }"
                                @click.prevent="activeTab = 'info'" href="#">Info</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'managerBrief' }"
                                @click.prevent="activeTab = 'managerBrief'" href="#">Manager's Brief</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'tasks' }"
                                @click.prevent="activeTab = 'tasks'" href="#">Subtasks</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'taskChat' }"
                                @click.prevent="activeTab = 'taskChat'" href="#">Task Chat</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'files' }"
                                @click.prevent="activeTab = 'files'" href="#">Files</a>
                        </li>
                    </ul>

                    <!-- Tab Content -->
                    <div class="tab-content">
                        <!-- Info Tab -->
                        <div v-if="activeTab === 'info'" class="tab-pane fade show active">
                            <div class="card">
                                <div class="card-body" v-if="!isEditingInfo">
                                    <div v-html="taskViewData.info?.body" v-if="taskViewData"></div>
                                </div>
                                <div class="card-body" v-if="isEditingInfo">
                                    <div class="mb-3" v-if="taskViewData">
                                        <Editor api-key="no-api-key" :init="tinymceInit"
                                            v-model="taskViewData.info.body"></Editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activeTab === 'files'" class="tab-pane fade show active">
                            <div class="card">
                                <div class="card-body">
                                    <iframe :src="taskViewData.info?.shared_link"
                                        style="width: 100%; height: 500px;"></iframe>
                                </div>
                            </div>
                        </div>

                        <!-- Manager's Brief Tab -->
                        <div v-if="activeTab === 'managerBrief'" class="tab-pane fade show active">
                            <div class="card">
                                <div class="card-body" v-if="!isEditingManagersBrief">
                                    <div v-html="taskViewData.info?.manager_brief" v-if="taskViewData"></div>
                                </div>
                                <div class="card-body" v-if="isEditingManagersBrief">
                                    <div class="mb-3" v-if="taskViewData">
                                        <Editor api-key="no-api-key" :init="tinymceInit"
                                            v-model="taskViewData.info.manager_brief"></Editor>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Tasks Tab -->
                        <div v-if="activeTab === 'tasks'" class="tab-pane fade show active">
                            <div class="card">
                                <div class="card-body table-responsive">
                                    <table id="myProjectTable" class="table table-hover align-middle mb-0 mis_table"
                                        style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>Subtask <i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('subtasks.title')"></i></th>
                                                <th>
                                                    <div v-if="superUser" class="d-flex align-items-center">
                                                        <select class="ms-1 nav-link rounded" name=""
                                                            id="dropdownManagers" @change="updateManagerFilter">
                                                            <option value="" selected>MANAGER</option>
                                                            <option v-for="manager in managers" :key="manager.id"
                                                                :value="manager.id">
                                                                {{ manager.username }}
                                                            </option>
                                                        </select>
                                                        <img src="../assets/images/icons8-superman.svg"
                                                            alt="Citrine Logo" class="menu-img ms-1" style="width:2em">
                                                    </div>
                                                    <div v-else>
                                                        Manager
                                                        <i class="fa fa-sort sorting-icon"
                                                            @click.prevent="sortBy('subtasks.managerId')"></i>
                                                    </div>
                                                </th>
                                                <th>Date Start <i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('subtasks.startDate')"></i></th>
                                                <th>Date End <i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('subtasks.endDate')"></i></th>
                                                <th>Status <i class="fa fa-sort sorting-icon"
                                                        @click.prevent="sortBy('subtasks.statusId')"></i></th>
                                                <th class="no-after"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(task, index) in data" :key="task.info.id">

                                                <td><router-link
                                                        :to="{ path: 'subtask', query: { id: task.info?.id } }">
                                                        {{ task.info?.title }}
                                                    </router-link></td>
                                                <td>
                                                    <img v-if="task.manager?.avatar_file_name"
                                                        :src="`/storage/avatars/${task.manager.avatar_file_name}`"
                                                        width="35" :title="task.manager.username">
                                                    <span v-else> {{ task.manager?.username }} </span>
                                                </td>

                                                <td>{{ task.info?.startDate }}</td>
                                                <td>{{ task.info?.endDate }}</td>

                                                <td>
                                                    <!-- Custom Status Dropdown for Each Task -->
                                                    <div class="status-dropdown">
                                                        <button class="status-button"
                                                            :style="getStatusStyle(task.status?.id)">
                                                            <i :class="getStatusIcon(task.status.id)"></i>
                                                            {{ getCurrentStatus(task.status.id) }}
                                                            <i class="fa fa-caret-down"
                                                                v-if="canEditTask(taskViewData) || canEditAllTasks || superUser"></i>
                                                        </button>
                                                        <div class="status-dropdown-content"
                                                            v-if="canEditTask(task) || canEditAllTasks || superUser">
                                                            <button v-for="status in statuses" :key="status.id"
                                                                :style="getStatusStyle(status?.id)"
                                                                @click="updateSubtaskStatusId(task.info.id, status.id)"
                                                                :class="{ 'active-status': task.status.id === status.id }">
                                                                <i :class="status.icon"></i>
                                                                {{ status.description }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>

                                                    <div class="button-group">
                                                        <div class="icon-wrapper">
                                                            <button class="btn btn-dark">
                                                                <img src="../assets/images/chat.png"
                                                                    alt="Unread comments" title="Unread comments"
                                                                    class="menu-img" style="width:1.5em">
                                                                <span class="unread-comments"
                                                                    v-if="task.unread_comments > 0">{{
                                                                        task.unread_comments }}</span>
                                                            </button>
                                                        </div>
                                                        <div class="icon-wrapper">
                                                            <button class="btn btn-dark"
                                                                @click.prevent="editSubTask(task.info.id)"
                                                                v-if="canEditSubtask(task) || canEditAllSubtasks || superUser">
                                                                <img src="../assets/images/edit.png" alt="Edit"
                                                                    title="Edit" class="menu-img" style="width:1.5em">
                                                            </button>
                                                        </div>
                                                        <div class="icon-wrapper">
                                                            <button class="btn btn-dark"
                                                                @click.prevent="openArchiveConfirmation(task.info?.id)"
                                                                v-if="canArchiveSubtask || superUser">
                                                                <img src="../assets/images/delete.png" alt="Archive"
                                                                    title="Archive" class="menu-img"
                                                                    style="width:1.5em">
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="!data || !data.length">
                                                <td colspan="6" class="text-center">No tasks found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav aria-label="Page navigation">
                                        <ul class="pagination mt-4"
                                            v-if="paginationLinks && paginationLinks.length > 0">
                                            <li class="page-item" v-for="(paginationLink, index) in paginationLinks"
                                                :key="index" :class="{ active: paginationLink.active }">
                                                <router-link
                                                    :to="{ path: 'task', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }"
                                                    v-if="paginationLink.url">
                                                    <span class="page-link" v-html="paginationLink.label"></span>
                                                </router-link>
                                                <span class="page-link" v-else v-html="paginationLink.label"></span>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <!-- Task Chat Tab -->
                        <div v-if="activeTab === 'taskChat'" class="tab-pane fade show active">
                            <div class="card">
                                <div class="card-body">
                                    <ul ref="chatHistory" class="chat-history list-unstyled mb-3">
                                        <li class="mb-3 d-flex" v-if="taskViewData && taskViewData.comments"
                                            v-for="comment in taskViewData.comments" :key="comment.id" :class="{
                                                'justify-content-end': comment.author?.id == currentUserId,
                                                'justify-content-start': comment.author?.id != currentUserId
                                            }">
                                            <div style="width:50%;">
                                                <span class="text-muted small d-flex align-items-center" :class="{
                                                    'justify-content-end': comment.author?.id == currentUserId,
                                                    'justify-content-start': comment.author?.id != currentUserId
                                                }">
                                                    <img v-if="comment.author?.avatar_file_name"
                                                        :src="`/storage/avatars/${comment.author?.avatar_file_name}`"
                                                        width="25">
                                                    <h6 class=fw-bolder>{{ comment.author.username }}</h6>

                                                </span>
                                                <div class="chat-message p-2 mt-1 rounded">
                                                    {{ comment.info?.comment }}
                                                </div>
                                                <span class="text-muted small">
                                                    <i class="icofont icofont-date"></i><img
                                                        src="../assets/images/diary.png" alt="Edit" title="Edit"
                                                        class="menu-img" style="width:1.5em">{{ comment.info.created_at
                                                    }}
                                                </span>
                                            </div>

                                        </li>
                                    </ul>
                                    <div class="d-flex">
                                        <textarea id="commentTextarea" class="form-control position-relative"
                                            placeholder="Αποστολή Μηνύματος" v-model="newComment"
                                            @input="filterUsernames"></textarea>

                                        <!-- Autocomplete Dropdown -->
                                        <div v-if="showAutocomplete" class="autocomplete-dropdown" :style="{
                                            top: `${dropdownTop}px`,
                                            left: `${dropdownLeft}px`,
                                            width: `${dropdownWidth}px`
                                        }">
                                            <ul class="list-unstyled m-0 p-2">
                                                <li v-for="user in filteredUsernames" :key="user.id"
                                                    @click="selectUsername(user.username)" class="autocomplete-item">
                                                    {{ user.username }}
                                                </li>
                                            </ul>
                                        </div>
                                        <button @click.prevent="submitComment" class="btn sendComment mt-2">
                                            <i class="fa fa-paper-plane"></i>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right Column with Task Details -->
                <div class="col-xl-4 col-lg-5 col-md-12" v-if="activeTab != 'tasks' && taskViewData">
                    <div class="card">
                        <div class="card-body">

                            <button class="btn btn-dark"
                                v-if="!isEditingGeneral && (superUser || canEditAllTasks || canEditTask(taskViewData))"
                                @click="editTaskGeneral" style="position: absolute; top: 25px; right: 10px;">
                                <img src="../assets/images/edit.png" alt="Edit" title="Edit" class="menu-img"
                                    style="width: 2em;">
                            </button>

                            <!-- Save Button -->
                            <button class="btn btn-secondary mb-3" @click="saveGeneral" v-if="isEditingGeneral"
                                style="position: absolute; top: 25px; right: 10px;">

                                <img src="../assets/images/save.png" alt="Save" title="Save" class="menu-img"
                                    style="width:2em">
                            </button>

                            <!-- Cancel Button -->
                            <button class="btn btn-danger mt-2" @click="cancelEditingGeneral" v-if="isEditingGeneral"
                                style="position: absolute; top: 65px; right: 10px;">

                                <img src="../assets/images/cancel.png" alt="Cancel" title="Cancel" class="menu-img"
                                    style="width:2em">
                            </button>

                            <div class="row" v-if="!isEditingGeneral">
                                <div class="col-12"><img src="../assets/images/diary.png" alt="Edit" title="Edit"
                                        class="menu-img" style="width:1.5em"><strong>Start Date:</strong> {{
                                    taskViewData.info.startDate_formatted }}</div>
                                <div class="col-12"><img src="../assets/images/diary.png" alt="Edit" title="Edit"
                                        class="menu-img" style="width:1.5em"><strong>End Date:</strong> {{
                                    taskViewData.info.endDate_formatted }}</div>
                                <br /><br />
                                <div class="col-12"><strong>Creator:</strong> <img
                                        v-if="taskViewData?.creator?.avatar_file_name"
                                        :src="`/storage/avatars/${taskViewData?.creator?.avatar_file_name}`" width="35"
                                        :title="taskViewData.creator.username" /></div>
                                <div class="col-12"><strong>Manager:</strong> <img
                                        v-if="taskViewData?.manager?.avatar_file_name"
                                        :src="`/storage/avatars/${taskViewData?.manager?.avatar_file_name}`" width="35"
                                        :title="taskViewData.manager.username" /></div>
                                <div style="display: inline-flex;">
                                    <div class="col-12"><strong>Assigned Users:</strong>
                                        <div v-for="user in taskViewData.assigned" :key="user.id"
                                            style="display: inline-flex; align-items: center; margin-right: 10px;">
                                            <img v-if="user?.avatar_file_name"
                                                :src="`/storage/avatars/${user.avatar_file_name}`" width="25"
                                                :title="user.username" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="isEditingGeneral">
                                <div class="col-8 d-flex align-items-center mb-3">
                                    <strong class="me-2" style="width: 150px; text-align: right;">Start Date:</strong>
                                    <input type="date" class="form-control flex-grow-1" v-model="taskData.startDate" />
                                </div>
                                <div class="col-8 d-flex align-items-center mb-3">
                                    <strong class="me-2" style="width: 150px; text-align: right;">End Date:</strong>
                                    <input type="date" class="form-control flex-grow-1" v-model="taskData.endDate" />
                                </div> <br /><br />

                                <div class="col-8 d-flex align-items-center mb-3">
                                    <strong class="me-2" style="width: 150px; text-align: right;">Creator:</strong>
                                    <Select2 :options="allData.users" v-model="taskData.createdBy"
                                        :settings="select2SettingsProject" class="flex-grow-1" />
                                </div>
                                <div class="col-8 d-flex align-items-center mb-3">
                                    <strong class="me-2" style="width: 150px; text-align: right;">Manager:</strong>
                                    <Select2 :options="allData.users" v-model="taskData.managerId"
                                        :settings="select2SettingsSubTask" class="flex-grow-1" />
                                </div>
                                <div class="col-8 d-flex align-items-center mb-3">
                                    <strong class="me-2" style="width: 150px; text-align: right;">Assigned
                                        Users:</strong>
                                    <Select2 :options="allData.users" v-model="taskData.assignedUsers"
                                        :settings="{ ...select2SettingsSubTask, multiple: true }" class="flex-grow-1" />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <!-- Right Column with Project Details -->
                <div class="col-xl-4 col-lg-5 col-md-3 rightCol" v-if="activeTab == 'tasks'">

                    <div class="card border-0 mb-2">
                        <div class="card-header mb-5 py-1 px-0 d-sm-flex align-items-center justify-content-between">
                            <div>
                                <button type="button" class="ms-0 ms-sm-3 btn"
                                    style="color:var(--secondary-color); font-weight:bold"
                                    @click.prevent="createSubtask" v-if="canCreateSubtask || superUser">
                                    <i class="icofont-plus me-2 fs-6"></i>Create Subtask
                                </button>
                            </div>

                        </div>
                        <div class="filter-section mt-3">
                            <div class="d-flex align-items-center mb-3 justify-content-center col-md-12">

                                <input id="main-search-input" type="search" class="form-control"
                                    placeholder="Αναζήτηση..." aria-label="search" aria-describedby="addon-wrapping" />
                            </div>

                            <!-- Status Tabs -->
                            <div>

                                <!-- Status Tabs -->
                                <ul class="nav nav-tabs tab-body-header rounded w-100 mb-2" role="tablist">
                                    <li class="nav-item2">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '0' || filters.statusId == undefined,
                                            'nav-link text-center': filters.statusId != '0' && filters.statusId != undefined,
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '0', page: 1 })"
                                            href="#" role="tab">
                                            All
                                        </a>
                                    </li>
                                    <li class="nav-item2">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '1',
                                            'nav-link text-center': filters.statusId != '1',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '1', page: 1 })"
                                            href="#" role="tab">
                                            <img src="../assets/images/on_hold.png" alt="On Hold" title="On Hold"
                                                class="menu-img" style="width:2.5em">
                                        </a>
                                    </li>
                                    <li class="nav-item2">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '2',
                                            'nav-link text-center': filters.statusId != '2',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '2', page: 1 })"
                                            href="#" role="tab">
                                            <img src="../assets/images/progress.png" alt="In Progress"
                                                title="In Progress" class="menu-img" style="width:2.5em">
                                        </a>
                                    </li>
                                    <li class="nav-item2">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '3',
                                            'nav-link text-center': filters.statusId != '3',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '3', page: 1 })"
                                            href="#" role="tab">
                                            <img src="../assets/images/done.png" alt="Done" title="Done"
                                                class="menu-img" style="width:2.5em">
                                        </a>
                                    </li>
                                    <li class="nav-item2">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '5',
                                            'nav-link text-center': filters.statusId != '5',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '5', page: 1 })"
                                            href="#" role="tab">
                                            <img src="../assets/images/on_going.png" alt="On going" title="On going"
                                                class="menu-img" style="width:2.5em">
                                        </a>
                                    </li>
                                    <li class="nav-item2">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '4',
                                            'nav-link text-center': filters.statusId != '4',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '4', page: 1 })"
                                            href="#" role="tab">
                                            <img src="../assets/images/archived.png" alt="Archived" title="Archived"
                                                class="menu-img" style="width:2.5em">
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            <!-- Date Filters -->
                            <div class="mb-3 d-flex align-items-center">
                                <label for="start-date" class="form-label me-2">Start date:</label>
                                <input type="date" id="start-date" v-model="startDate" class="form-control w-auto" />
                            </div>

                            <div class="mb-3 d-flex align-items-center">
                                <label for="end-date" class="form-label me-2">End date:</label>
                                <input type="date" id="end-date" v-model="endDate" class="form-control w-auto" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="unsavedChangesModal" tabindex="-1" aria-labelledby="unsavedChangesLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="unsavedChangesLabel">Unsaved Changes</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                You may have unsaved changes. Are you sure you want to leave this page?
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-danger" @click="confirmLeave">Leave</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Create Task -->
                <div class="modal fade" id="create-task-form" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title fw-bold">Edit Task</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <input type="hidden" name="projectId" v-model="taskData.projectId">
                            <div class="modal-body">
                                <!-- Task Data Form -->
                                <div class="mb-3">
                                    <label class="form-label">Title</label>
                                    <input type="text" class="form-control" placeholder="Title" name="title"
                                        v-model="taskData.title">
                                </div>
                                <div class="mb-3"
                                    v-if="(taskData && taskData.createdBy == currentUserInfo?.id) || superUser || taskData.createdBy == '' || taskData.createdBy == null">
                                    <label for="taskDescriptionTinyMce" class="form-label">Creator's brief</label>
                                    <Editor api-key="no-api-key" :init="tinymceInit" v-model="taskData.body"></Editor>
                                </div>
                                <div class="mb-3"
                                    v-if="(taskData && currentUserInfo && taskData.managerId == currentUserInfo.id)">
                                    <label for="taskManagerBriefTinyMce" class="form-label">Manager's brief</label>
                                    <Editor api-key="no-api-key" :init="tinymceInit" v-model="taskData.manager_brief">
                                    </Editor>
                                </div>
                                <div class="mb-3" v-if="superUser || canEditTaskCreator">
                                    <label class="form-label">Creator</label>
                                    <Select2 :options="allData.users" v-model="taskData.createdBy"
                                        :settings="select2SettingsProject" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Status</label>
                                    <Select2 :options="allData.statuses" v-model="taskData.statusId"
                                        :settings="select2SettingsTask" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Manager</label>
                                    <Select2 :options="allData.users" v-model="taskData.managerId"
                                        :settings="select2SettingsTask" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Assigned users</label>
                                    <Select2 :options="allData.users" v-model="taskData.assignedUsers"
                                        :settings="{ ...select2SettingsTask, multiple: true }" />
                                </div>
                                <div class="deadline-form">
                                    <form>
                                        <div class="row g-3 mb-3">
                                            <div class="col">
                                                <label for="taskStartDate" class="form-label">Start Date</label>
                                                <input type="date" class="form-control" name="startDate"
                                                    v-model="taskData.startDate">
                                            </div>
                                            <div class="col">
                                                <label for="taskEndDate" class="form-label">End Date</label>
                                                <input type="date" class="form-control" name="endDate"
                                                    v-model="taskData.endDate">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div id="create-project-errors"></div>
                            </div>
                            <div class="modal-footer">
                                <!--                     <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
 --> <button type="button" class="btn btn-secondary" @click.prevent="submitTaskForm">Update</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="archiveConfirmationModal" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-md">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title fw-bold">Confirm Archive</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p>Are you sure you want to archive this subtask?</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-danger" @click="confirmArchive">Yes,
                                    Archive</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Subtask Modal -->
                <div class="modal fade" id="create-subtask-form" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title fw-bold">Sub Task Data</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <input type="hidden" name="id" id="id" v-model="subTaskData.id">
                                <div class="mb-3">
                                    <label for="taskTitle" class="form-label">Title</label>
                                    <input type="text" class="form-control" placeholder="Title" name="title"
                                        v-model="subTaskData.title">
                                </div>
                                <div class="mb-3"
                                    v-if="(subTaskData && subTaskData.createdBy == currentUserInfo?.id) || superUser || subTaskData.createdBy == '' || subTaskData.createdBy == null">
                                    <label class="form-label">Creator's brief</label>
                                    <Editor api-key="no-api-key" :init="tinymceInit" v-model="subTaskData.body">
                                    </Editor>
                                </div>
                                <div class="mb-3"
                                    v-if="(subTaskData && currentUserInfo && subTaskData.managerId == currentUserInfo.id)">
                                    <label class="form-label">Manager's brief</label>
                                    <Editor api-key="no-api-key" :init="tinymceInit"
                                        v-model="subTaskData.manager_brief"></Editor>
                                </div>
                                <div class="mb-3" v-if="superUser || canEditSubTaskCreator">
                                    <label class="form-label">Creator</label>
                                    <Select2 :options="allData.users" v-model="subTaskData.createdBy"
                                        :settings="select2SettingsProject" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Status</label>
                                    <Select2 :options="allData.statuses" v-model="subTaskData.statusId"
                                        :settings="select2SettingsSubTask" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Manager</label>
                                    <Select2 :options="allData.users" v-model="subTaskData.managerId"
                                        :settings="select2SettingsSubTask" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Assigned users</label>
                                    <Select2 :options="allData.users" v-model="subTaskData.assignedUsers"
                                        :settings="{ ...select2SettingsSubTask, multiple: true }" />
                                </div>
                                <div class="deadline-form">
                                    <form>
                                        <div class="row g-3 mb-3">
                                            <div class="col">
                                                <label for="taskStartDate" class="form-label">Start Date</label>
                                                <input type="date" class="form-control" name="startDate"
                                                    v-model="subTaskData.startDate">
                                            </div>
                                            <div class="col">
                                                <label for="projectEndDate" class="form-label">End Date</label>
                                                <input type="date" class="form-control" name="endDate"
                                                    v-model="subTaskData.endDate">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div id="create-project-errors"></div>
                            </div>
                            <div class="modal-footer">
                                <!--                     <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
 --> <button type="button" class="btn btn-secondary" @click.prevent="submitSubTaskForm">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useFetchData } from '@/composables/useFetchData';
import { getClientsServicesStatusesUsers, createComment, updateInfo, updateManager, updateGeneral } from '@/services/tasks';
import { fetchAll } from '@/services/subtasks';
import { get as getTask, searchUsers } from '@/services/tasks';
import { get as getSubtask } from '@/services/subtasks';
import { createOrUpdate as createOrUpdateSubtask, massArchiveSubTasks } from '@/services/subtasks';
import { createOrUpdate as createOrUpdateTask } from '@/services/tasks';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import Editor from '@tinymce/tinymce-vue';
import Select2 from 'vue3-select2-component';
import { mapGetters } from 'vuex';
import { fetchUserInfo } from '@/services/user';
import { all } from 'axios';
import { updateTaskStatus } from '@/services/todo';
import { updateSubtaskStatus } from '@/services/todo';
import { fetchManagers } from '@/services/followup';


export default {
    name: 'Task',
    components: {
        Editor,
        Select2
    },
    data() {
        return {
            showUnsavedModal: false,
            isEditingInfo: false,
            isEditingGeneral: false,
            isEditingManagersBrief: false,
            pendingNavigation: null, // Store pending navigation details
        };
    },
    methods: {
        confirmLeave() {
            this.resetEditingStates();
            if (this.pendingNavigation) {
                this.pendingNavigation();
                this.pendingNavigation = null;
            }
            const modal = $('#unsavedChangesModal');
            modal.modal('hide');
        },
        resetEditingStates() {
            this.isEditingInfo = false;
            this.isEditingManagersBrief = false;
            this.isEditingGeneral = false;
        },
        canEditTask(task) {
            if (!task) { return false; }
            return localStorage.getItem('current_user_id') == task.info?.createdBy && this.userPermissionsTasks?.edit === 1;
        },
        canEditSubtask(subtask) {
            if (!subtask) { return false; }
            return localStorage.getItem('current_user_id') == subtask.info?.createdBy && this.userPermissionsSubTasks?.edit === 1;
        },
        scrollToEnd() {
            this.$nextTick(() => {
                const chatHistory = this.$refs.chatHistory;
                if (chatHistory) {
                    chatHistory.scrollTop = chatHistory.scrollHeight;
                }
            });
        },

        editTask() {
            this.isEditingInfo = true;
        },
        editTaskManager() {
            this.isEditingManagersBrief = true;
        },

        cancelEditing() {
            this.isEditingInfo = false;

        },
        cancelEditingManager() {
            this.isEditingManagersBrief = false;
        },

        async saveInfo() {
            try {
                // Prepare the payload with both `id` and `body`
                const payload = {
                    id: this.$route.params.id, // ID from route parameters
                    body: this.taskViewData.info.body // Editor content
                };

                const toast = useToast();


                // Call the updateInfo service with the correct payload
                const response = await updateInfo(this.taskViewData.info);

                // Show success message
                toast.success(response.data.message);

                // Exit editing mode
                this.isEditingInfo = false;
            } catch (error) {
                // Handle any errors that occur during the API call
                console.error('Error updating information:', error);
            }
        },

        async saveManager() {
            try {
                // Prepare the payload with both `id` and `body`
                const payload = {
                    id: this.$route.params.id, // ID from route parameters
                    manager_brief: this.taskViewData.info.manager_brief // Editor content
                };

                const toast = useToast();


                // Call the updateInfo service with the correct payload
                const response = await updateManager(this.taskViewData.info);

                // Show success message
                toast.success(response.data.message);

                // Exit editing mode
                this.isEditingManagersBrief = false;
            } catch (error) {
                // Handle any errors that occur during the API call
                console.error('Error updating information:', error);
            }
        },

        submitComment() {
            // Your submit comment logic here
            this.scrollToEnd(); // Scroll to end after adding new comment
        },
        async fetchManagersData() {
            try {
                const response = await fetchManagers();
                if (response.data.success) {
                    this.managers = response.data.data;
                }
            } catch (error) {
                console.error('Failed to fetch managers:', error);
            }
        },
        async fetchCreatorsData() {
            try {
                const response = await fetchManagers();
                if (response.data.success) {
                    this.creators = response.data.data;
                } else {
                    console.error("Failed to fetch creators:", response.data.message);
                }
            } catch (error) {
                console.error("Failed to fetch creators:", error);
            }
        }
    },
    computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission'
        }),
        canCreate() {
            return this.userPermissionsTasks?.create === 1;
        },
        canUpdate() {
            return this.userPermissionsTasks?.create === 1;
        },
        canArchive() {
            return this.userPermissionsSubTasks?.archive === 1;
        },
        canArchiveSubtask() {
            return this.userPermissionsSubTasks?.archive === 1;
        },
        canCreateSubtask() {
            return this.userPermissionsSubTasks?.create === 1;
        },
        userPermissionsTasks() {
            return this.getUserPermission('Tasks');
        },
        userPermissionsSubTasks() {
            return this.getUserPermission('Subtasks');
        },
        canEditTaskCreator() {
            return this.userPermissionsTasks?.editCreator === 1;
        },
        canEditSubTaskCreator() {
            return this.userPermissionsSubTasks?.editCreator === 1;
        },
        canEditAllTasks() {
            return this.userPermissionsTasks?.editAll === 1;
        },
        canEditAllSubtasks() {
            return this.userPermissionsSubTasks?.editAll === 1;
        },
        superUser() {
            return this.getSuperUserPermission === 1;
        },
        getUser() {
            return this.$store.state.userInfo.id;
        },
        currentUserId() {
            return localStorage.getItem('current_user_id'); // Safely retrieve the ID once
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.isEditingInfo || this.isEditingManagersBrief || this.isEditingGeneral) {
            this.pendingNavigation = next;
            const modal = $('#unsavedChangesModal');
            modal.modal('show');
        } else {
            next();
        }
    },
    mounted() {
        // Initial scroll to end after the component mounts
        this.scrollToEnd();
        this.fetchManagersData();
        this.fetchCreatorsData();
    },
    watch: {
        'taskViewData.comments': function (newComments) {
            if (newComments && newComments.length) {
                // Scroll to the end when comments are loaded or updated
                this.scrollToEnd();
            }
        }
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const toast = useToast();
        const activeTab = ref('info');
        const newComment = ref('');
        const startDate = ref('');
        const endDate = ref('');
        const showAutocomplete = ref(false);
        const subtaskIdToArchive = ref(null); // Store the ID of the task to archive
        const users = ref([]);
        const managers = ref([]);
        const creators = ref([]);
        const isEditingGeneral = ref(false); // Ensure this is defined as a ref

        const openArchiveConfirmation = (subtaskId) => {
            subtaskIdToArchive.value = subtaskId;
            $('#archiveConfirmationModal').modal('show'); // Show the confirmation modal
        };

        const confirmArchive = async () => {
            if (!subtaskIdToArchive.value) return;

            try {
                const response = await massArchiveSubTasks({ ids: [subtaskIdToArchive.value] });
                if (response.data.success) {
                    useToast().success('Subtask archived successfully');
                    fetchData(); // Refresh the subtasks list
                } else {
                    useToast().error('Failed to archive subtask');
                }
            } catch (error) {
                useToast().error('An error occurred while archiving');
            } finally {
                $('#archiveConfirmationModal').modal('hide'); // Close the modal
                subtaskIdToArchive.value = null;
            }
        };



        const filterUsernames = () => {
            const input = newComment.value;
            const match = input.match(/@(\w*)$/); // Match @username pattern

            if (match) {
                const searchTerm = match[0].substring(1); // Extract text after '@'

                // Call the filtering logic only if there are at least 2 characters after "@"
                if (searchTerm.length >= 2) {

                    const query = match[1].toLowerCase();
                    filteredUsernames.value = users.value.filter(user =>
                        user.username.toLowerCase().includes(query)
                    );
                    showAutocomplete.value = filteredUsernames.value.length > 0;
                } else {
                    showAutocomplete.value = false;
                }
            } else {
                showAutocomplete.value = false;
            }
        };

        // Watch for changes to startDate and endDate and update URL filters
        watch([startDate, endDate], ([newStartDate, newEndDate]) => {
            router.push({
                path: '/task',
                query: { ...route.query, startDate: newStartDate, endDate: newEndDate },
            });
        });

        const taskData = ref({
            id: '',
            projectId: '0',
            title: '',
            description: '',
            assignedUsers: [],
            managerId: '',
            statusId: '',
            startDate: '',
            endDate: ''
        });

        const subTaskData = ref({
            id: '',
            taskId: route.query.id || 0,
            title: '',
            body: '',
            assignedUsers: [],
            managerId: '',
            statusId: '0',
            startDate: '',
            endDate: ''
        });

        const allData = ref({});
        const taskViewData = ref(null);
        const selectedSubTaskIds = ref([]);
        const isDropdownOpen = ref(false);
        const filteredUsernames = ref([]);
        const currentUserInfo = ref(null);


        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { id: route.query.id, page: route.query.page || 1, archived: route.query.archived || 0, statusId: route.query.statusId || 2 }, { id: route.query.id, page: 1, archived: 0, statusId: 2 });

        const toggleSubTaskSelection = (subTaskId) => {
            if (!selectedSubTaskIds.value.includes(subTaskId)) {
                selectedSubTaskIds.value.push(subTaskId);
            } else {
                selectedSubTaskIds.value = selectedSubTaskIds.value.filter(id => id !== subTaskId);
            }
        };

        const createSubtask = async () => {
            const tmpAllData = await getClientsServicesStatusesUsers({ params: { type: "tasks" } });
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            subTaskData.value = {
                taskId: route.query.id || 0,
                title: '',
                manager_brief: '',
                body: '',
                assignedUsers: [],
                managerId: '',
                statusId: '0',
                totalHours: '',
                startDate: '',
                endDate: ''
            };
            $("#create-subtask-form").modal('toggle');
        };

        const getStatusStyle = (id) => {
            switch (id) {
                case 2:
                    return 'background: #1CA6F1; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em; font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; align-items:center; height:2.5em';
                case 3:
                    return 'background: #A5CF27; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center;  height:2.5em';
                case 1:
                    return 'background: #B1B1B1; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:2.5em';
                case 5:
                    return 'background: #ECC308; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:2.5em';
                case 4:
                    return 'background: #796EEE; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:2.5em';
                case 6:
                    return 'background: #E4364D; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:2.5em';
                default:
                    return 'text-align:center';
            }
        };
        const submitComment = async () => {
            const response = await createComment({ "id": route.query?.id, comment: newComment.value });
            if (response.data.success) {
                newComment.value = '';
                taskViewData.value = (await getTask({ id: route.query?.id })).data.data;
                $(".chat-history").animate({ scrollTop: $(".chat-history")[0].scrollHeight }, 1000);
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        };

        /* const editTask = async (id) => {
            const tmpAllData = await getClientsServicesStatusesUsers({ params: { type: "tasks" } });
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            const taskInfo = await getTask({ id });
            let taskDataValue = taskInfo.data.data.info;
            taskDataValue.managerId = taskInfo.data.data.manager?.id;
            taskDataValue.statusId = taskInfo.data.data.status?.id;
            taskDataValue.assignedUsers = taskInfo.data.data.assigned?.map((u) => u.id);
            taskDataValue.projectId = taskInfo.data.data.project?.id;
            taskData.value = taskDataValue;
            $("#create-task-form").modal('toggle');
        }; */

        const editSubTask = async (id) => {
            const tmpAllData = await getClientsServicesStatusesUsers({ params: { type: "subtasks" } });
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            const subTaskInfo = await getSubtask({ id });
            let subtaskDataValue = subTaskInfo.data.data.info;
            subtaskDataValue.managerId = subTaskInfo.data.data.manager?.id;
            subtaskDataValue.statusId = subTaskInfo.data.data.status?.id;
            subtaskDataValue.assignedUsers = subTaskInfo.data.data.assigned?.map((u) => u.id);
            subtaskDataValue.projectId = subTaskInfo.data.data.project?.id;
            subTaskData.value = subtaskDataValue;
            $("#create-subtask-form").modal('toggle');
        };

        const saveGeneral = async () => {
            const response = await updateGeneral(taskData.value);
            response.data.success ? toast.success(response.data.message) : toast.error(response.data.message);
            taskViewData.value = (await getTask({ id: route.query.id })).data.data;
            fetchData();
            isEditingGeneral.value = false;
        };

        const editTaskGeneral = async () => {
            try {
                const tmpAllData = await getClientsServicesStatusesUsers({ params: { type: "subtasks" } });
                let newData = { users: [], statuses: [], clients: [], services: [] };

                for (let i = 0; i < tmpAllData.data.users.length; i++) {
                    newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
                }
                for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                    newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
                }
                for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                    newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
                }
                for (let i = 0; i < tmpAllData.data.services.length; i++) {
                    newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
                }

                allData.value = newData; // `allData` is a ref, so use `.value`

                const taskInfo = await getTask({ id: route.query.id });
                let taskDataValue = taskInfo.data.data.info;
                taskDataValue.managerId = taskInfo.data.data.manager?.id;
                taskDataValue.statusId = taskInfo.data.data.status?.id;
                taskDataValue.assignedUsers = taskInfo.data.data.assigned?.map((u) => u.id);
                taskDataValue.projectId = taskInfo.data.data.project?.id;

                taskData.value = taskDataValue; // `subTaskData` is a ref, so use `.value`

            } catch (error) {
                console.error('Error in editSubTaskGeneral:', error);
            }

            isEditingGeneral.value = true; // Use `.value` for reactive refs
        };

        const cancelEditingGeneral = async () => {
            isEditingGeneral.value = false;

        };


        const submitTaskForm = async () => {
            const response = await createOrUpdateTask(taskData.value);
            response.data.success ? toast.success(response.data.message) : toast.error(response.data.message);
            taskViewData.value = (await getTask({ id: route.query.id })).data.data;
            $("#create-task-form").modal('toggle');
        };

        const submitSubTaskForm = async () => {
            const response = await createOrUpdateSubtask(subTaskData.value);
            response.data.success ? toast.success(response.data.message) : toast.error(response.data.message);
            fetchData();
            $("#create-subtask-form").modal('toggle');
        };

        const dropdownStyle = computed(() => ({
            top: isDropdownOpen.value ? '100%' : 'initial',
            right: isDropdownOpen.value ? '0' : 'initial'
        }));

        const toggleDropdown = () => {
            isDropdownOpen.value = !isDropdownOpen.value;
        };

        const closeDropdown = () => {
            isDropdownOpen.value = false;
        };

        const tinymceInit = {
            height: 300,
            license_key: 'gpl',
            skin_url: 'https://cdn.jsdelivr.net/npm/tinymce@5.10.2/skins/ui/oxide',
            content_css: 'https://cdn.jsdelivr.net/npm/tinymce@5.10.2/skins/content/default/content.css'
        };

        const select2SettingsTask = {
            dropdownParent: '#create-task-form .modal-content',
            placeholder: "Please select"
        };

        const handleKeyup = async (event) => {
            const mentionQuery = event.target.value.split('@').pop();
            const unamesResponse = await searchUsers({ search: mentionQuery });
            filteredUsernames.value = unamesResponse.data.data.map(user => user.info.username);
        };

        const selectUsername = (username) => {
            newComment.value = newComment.value.replace(/@(\w*)$/, `@${username} `);
            filteredUsernames.value = [];
            showAutocomplete.value = false;
        };

        const updateManagerFilter = (event) => {
            const newManagerId = event.target.value;
            updateFilters({ ...filters.value, managerFilter: newManagerId, page: 1 });
        };

        const selectStatus = async (taskId, statusId) => {
            const r = await updateTaskStatus(taskId, statusId);
            if (r.data.success) {
                useToast().success(r.data.message);
                const taskInfo = await getTask({ id: taskId });
                taskViewData.value = taskInfo.data.data;
            } else {
                useToast().error(r.data.message);
            }
        };

        const statuses = ref([]);

        const getStatusIcon = (id) => {
            const status = statuses.value.find((s) => s.id === id);
            return status ? status.icon : "";
        };

        const getCurrentStatus = (id) => {
            const status = statuses.value.find((s) => s.id === id);
            return status ? status.description : "Select Status";
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({ path: '/task', query: { ...route.query, search: $("#main-search-input").val() } });
            }, 300);
        };

        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const handleClickOutside = (event) => {
            const dropdown = document.querySelector('.autocomplete-dropdown');
            const textarea = document.getElementById('commentTextarea');

            if (
                (dropdown && !dropdown.contains(event.target)) &&
                (!textarea || !textarea.contains(event.target))
            ) {
                showAutocomplete.value = false;
            }
        };


        onMounted(async () => {
            const allRequiredData = await getClientsServicesStatusesUsers({ params: { type: "tasks" } });
            statuses.value = allRequiredData.data.statuses;
            users.value = allRequiredData.data.users;
            currentUserInfo.value = (await fetchUserInfo()).data.info;
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
            taskViewData.value = (await getTask({ id: route.query.id })).data.data;
            document.addEventListener('click', handleClickOutside);
        });

        onUnmounted(() => {
            $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
            document.removeEventListener('click', handleClickOutside);

        });

        const updateSubtaskStatusId = async (subtaskId, statusId) => {
            const r = await updateSubtaskStatus(subtaskId, statusId);
            if (r.data.success) {
                useToast().success(r.data.message);
                fetchData();
            } else {
                useToast().error(r.data.message);
            }
        };

        return {
            currentUserInfo,
            sortBy,
            showAutocomplete,
            selectUsername,
            filterUsernames,
            filteredUsernames,
            confirmArchive,
            openArchiveConfirmation,
            startDate,
            endDate,
            newComment,
            updateSubtaskStatusId,
            createSubtask,
            selectStatus,
            data,
            filters,
            updateFilters,
            paginationLinks,
            activeTab,
            route,
            getPageFromUrl,
            statuses,
            getStatusIcon,
            getCurrentStatus,
            searchAction,
            allData,
            taskData,
            subTaskData,
            taskViewData,
            selectedSubTaskIds,
            toggleDropdown,
            closeDropdown,
            isDropdownOpen,
            dropdownStyle,
            tinymceInit,
            select2SettingsTask,
            submitTaskForm,
            submitSubTaskForm,
            submitComment,
            editSubTask,
            handleKeyup,
            filteredUsernames,
            selectUsername,
            getStatusStyle,
            updateManagerFilter,
            managers,
            editTaskGeneral,
            isEditingGeneral,
            cancelEditingGeneral,
            saveGeneral
        };
    },
};
</script>

<style scoped>
.chat-message {
    color: black !important;
}

.project-code {
    font-size: 1.5rem;
    font-weight: bold;
}

.status-dropdown {
    position: relative;
    display: inline-block;
}

.status-button {
    background-color: #f1f1f1;
    color: #333;
    padding: 8px 16px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 8px;

}

.status-dropdown:hover .status-dropdown-content {
    display: flex;
}

.status-dropdown-content {
    z-index: 9000;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 8px;
    display: none;
    flex-direction: column;

}

.status-dropdown>button {
    height: 1.5em;
}

.status-dropdown-content button {
    width: 100%;
    background: none;
    border: none;
    padding: 10px;
    text-align: left;
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 3px;

}

.status-dropdown-content button:hover {
    background-color: #461010;
}

.active-status {
    font-weight: bold;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
}

.create-task-button {
    width: 100%;
}

.autocomplete-dropdown {
    position: absolute;
    z-index: 1000;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    /* Limit height */
    overflow-y: auto;
}

.autocomplete-dropdown ul {
    padding: 0;
    margin-top: 110px;
}

.autocomplete-item {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.autocomplete-item:hover {
    background-color: #f0f0f0;
    color: #333;
}

textarea {
    resize: none;
    overflow: auto;
}

textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}


.nav-tabs {
    border: none;
}

/* Style for all list items to have a bottom border */
.nav-tabs .nav-item {
    border-bottom: 1px solid #DCDCDC;
    /* Light gray border for non-active items */
    margin-left: 20px;
}

.nav-tabs .nav-item .nav-link {
    color: #8C8C8C;
}

/* Style for the active tab to have a darker bottom border */
.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item .nav-link:hover {
    border-color: white;

    border-bottom: 3px solid #8C8C8C;
    /* Darker gray border for the active tab */
    color: black;

}


/* Optionally, you can remove the bottom border for non-active tabs on hover to make the UI clearer */

/* Add this CSS to ensure the button is placed on the right border */
.col-xl-8.col-lg-7.col-md-12 {
    position: relative;
    /* Make the parent div relative */
}

/* Style the button to be positioned absolutely at the border-right */
.status-button-wrapper {
    position: absolute;
    /* Position it absolutely within the parent */
    top: 50px;
    /* Vertically center the button */
    right: -1.5em;
    /* Position it slightly outside to touch the border-right */
    transform: translateY(-50%);
    /* Adjust for exact vertical centering */
    z-index: 10;
    /* Ensure the button stays above other elements */
}
</style>
