<template>
  <div class="body d-flex py-lg-3 py-md-2">
    <div class="container-xxl">
      <div class="row clearfix">
        <div class="col-md-12">
          <div class="row">
            <!-- Table Column -->
            <div :class="{ 'col-md-12': !showFilters, 'col-md-9 leftCol': showFilters }">
              <div class="card-body table-responsive">
                <table id="projectTable" class="table table-hover align-middle mb-0 mis_table" style="width:100%">
                  <thead>
                    <tr>
                      <th style="width:20%;">
                        <div>
                          Title
                          <i class="fa fa-sort sorting-icon" @click.prevent="sortBy(sortKeys.title)"></i>
                        </div>
                      </th>
                      <th style="width:5%;">
                        <div>
                          Dates
                          <i class="fa fa-sort sorting-icon" @click.prevent="sortBy(sortKeys.startDate)"></i>
                        </div>
                      </th>
                     
                      <th style="width:20%;">
  <div v-if="superUser" class="d-flex align-items-center">
    <select class="ms-3 nav-link rounded" name="" id="dropdownManagers"
      @change="updateManagerFilter">
      <option value="" selected>MANAGER</option>
      <option v-for="manager in managers" :key="manager.id" :value="manager.id">
        {{ manager.username }}
      </option>
    </select>
    <img src="../assets/images/icons8-superman.svg" alt="Citrine Logo" class="menu-img ms-2" style="width:2em">
  </div>
  <div v-else>
    Manager
    <i class="fa fa-sort sorting-icon" @click.prevent="sortBy('m.username')"></i>
  </div>
</th>

                      <th style="width:20%;" class="no-after">
                        <div>
                          Status
                          <i class="fa fa-sort sorting-icon" @click.prevent="sortBy(sortKeys.statusId)"></i>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(project, index) in data" :key="project.info.id" :class="{ 'expired-row': project.info.expired }">
                      <td>
                        <span class="current-code" :class="{ 'text-danger': project.info.expired }" 
                        @click="openInNewTab(getEntityType(project), project.info?.id)">{{ project.info?.title }}</span>
                        <span v-if="project.info.expired" class="badge bg-danger ms-2">Expired</span>
                        <br v-if="project.projectInfo">
  
  <!-- Display task title if taskInfo exists -->
  <span v-if="project.taskInfo" class="task-code" :class="{ 'disabled-link': !canAccessTask(project.taskInfo) }" 
  @click="canAccessTask(project.taskInfo) && openInNewTab('task', project.taskInfo?.id)">{{ project.taskInfo?.title }}</span><br v-if="project.taskInfo">

  <!-- Dynamically determine the link path based on projectInfo -->
  <span 
  v-if="project.projectInfo" 
  class="project-code" 
  :class="{ 'disabled-link': !canAccessProject(project.projectInfo) }" 
  @click="canAccessProject(project.projectInfo) && openInNewTab('project', project.projectInfo?.id)">
  {{ project.projectInfo?.title }}
</span>
</td>

                      <td>{{ project.info?.startDate }} {{ project.info?.endDate }}</td>

                      <td><img class="avatar md rounded-circle img-thumbnail" v-if="project.manager?.avatar_file_name" :src="`/storage/avatars/${project.manager.avatar_file_name}`" width="25" style="border-radius:50%" :title="`${project.manager.username}`"></td>
<!--                       <td>{{ project.client?.nickname }}</td>
 -->                      <td>
                        <div class="dropdown" >
                          <button class="dropbtn"   :style="{ backgroundColor: project.info.expired ? '#ffe6e6' : '' }">
                           
                            <img 
                                :src="getStatusIcon(project.status.id)" 
                            :alt="s" 
                            class="menu-img ms-2" 
                             style="width:2em"
                             />
                          </button>
                          <div class="dropdown-content" v-if="project.info.type === 'project'">
                          <button v-if="canEditProject(project) || canEditAllProjects || superUser" v-for="status in statuses" :key="status.id" @click="updateStatus(project.info?.id, status.id)">
                            <img 
  :src="getStatusIcon(status.id)" 
  :alt="status.description"
  :title="status.description"

    class="menu-img ms-2" 
    style="width:2em"
  >
                          </button>
                        </div>

                        <div class="dropdown-content"  v-if="project.info.type === 'task'">
                          <button v-if="canEditTask(project) ||  canEditAllTasks ||superUser" v-for="status in statuses" :key="status.id" @click="updateStatus(project.info?.id, status.id)">
                            <img 
  :src="getStatusIcon(status.id)" 
  :alt="status.description"
  :title="status.description"

    class="menu-img ms-2" 
    style="width:2em"
  >
                          </button>
                        </div>

                        <div class="dropdown-content"  v-if="project.info.type === 'subtask'">
                          <button v-if="canEditSubTask(project) ||  canEditAllSubTasks || superUser" v-for="status in statuses" :key="status.id" @click="updateStatus(project.info?.id, status.id)">
                            <img 
  :src="getStatusIcon(status.id)" 
  :alt="status.description"
  :title="status.description"

    class="menu-img ms-2" 
    style="width:2em"
  >
                          </button>
                        </div>
                        </div>
                      </td>
                    </tr>
                    <tr class="col" v-if="!data || !data.length">
                      <td>No data</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>

                <!-- Pagination -->
                <nav aria-label="Page navigation">
                  <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                    <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index"
                      :class="{ active: paginationLink.active }">
                      <router-link
                        :to="{ path: 'follow-up', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }"
                        v-if="paginationLink.url">
                        <span class="page-link" v-html="paginationLink.label"></span>
                      </router-link>
                      <span class="page-link" v-else v-html="paginationLink.label"></span>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <!-- Filters Column -->
            <div class="col-md-3 rightCol " v-if="showFilters">
              <div class="card border-0 mb-2">
                <div class="card-header py-1 px-0 d-sm-flex align-items-center justify-content-between">
                  <h3 class="fw-bold mb-0">Filters</h3>
                </div>
                <div class="filter-section mt-3">
                  <div class="d-flex align-items-center mb-3">
  <select class="ms-3 nav-link rounded me-2 selectSearch" v-model="searchArea">
    <option value="title" selected>Title</option>
    <option value="clients">Clients</option>
  </select>
  <input
    id="main-search-input"
    type="search"
    class="form-control"
    placeholder="Αναζήτηση..."
    aria-label="search"
    aria-describedby="addon-wrapping"
  />
</div>

                  <!-- Entity Tabs -->
                  <ul class="nav nav-tabs tab-body-header rounded w-100 mb-2" role="tablist">
                    <li class="nav-item">
                      <a :class="{
                        'nav-link text-center active': filters.entity == 'all' || filters.entity == undefined,
                        'nav-link text-center': filters.entity != 'all',
                      }" @click.prevent="() => updateFilters({ ...filters, entity: 'all', page: 1 })" href="#"
                        role="tab">
                        All
                      </a>
                    </li>
                    <li class="nav-item">
                      <a :class="{
                        'nav-link text-center active': filters.entity == 'projects',
                        'nav-link text-center': filters.entity != 'projects',
                      }" @click.prevent="() => updateFilters({ ...filters, entity: 'projects', page: 1 })" href="#"
                        role="tab">
                        P
                      </a>
                    </li>
                    <li class="nav-item">
                      <a :class="{
                        'nav-link text-center active': filters.entity == 'tasks',
                        'nav-link text-center': filters.entity != 'tasks',
                      }" @click.prevent="() => updateFilters({ ...filters, entity: 'tasks', page: 1 })" href="#"
                        role="tab">
                        T
                      </a>
                    </li>
                    <li class="nav-item">
                      <a :class="{
                        'nav-link text-center active': filters.entity == 'subtasks',
                        'nav-link text-center': filters.entity != 'subtasks',
                      }" @click.prevent="() => updateFilters({ ...filters, entity: 'subtasks', page: 1 })" href="#"
                        role="tab">
                        S
                      </a>
                    </li>
                  </ul>

                  <!-- Status Tabs -->
                  <ul class="nav nav-tabs tab-body-header rounded w-100 mb-2" role="tablist">
  <li class="nav-item">
    <a :class="{ 'nav-link text-center active': filters.statusId == '0' || filters.statusId == undefined, 'nav-link text-center': filters.statusId != '0' && filters.statusId != undefined }" 
       @click.prevent="() => updateFilters({ ...filters, statusId: '0', page: 1 })" href="#" role="tab">
      All
    </a>
  </li>
  <li class="nav-item">
    <a :class="{ 'nav-link text-center active': filters.statusId == '1', 'nav-link text-center': filters.statusId != '1' }" 
       @click.prevent="() => updateFilters({ ...filters, statusId: '1', page: 1 })" href="#" role="tab">
      <img src="../assets/images/on_hold.png" alt="On Hold" title="On Hold" class="menu-img ms-2" style="width:2em;" >
    </a>
  </li>
  <li class="nav-item">
    <a :class="{ 'nav-link text-center active': filters.statusId == '2', 'nav-link text-center': filters.statusId != '2' }" 
       @click.prevent="() => updateFilters({ ...filters, statusId: '2', page: 1 })" href="#" role="tab">
      <img src="../assets/images/progress.png" alt="In Progress" title="In Progress" class="menu-img ms-2" style="width:2em">
    </a>
  </li>
  <li class="nav-item">
    <a :class="{ 'nav-link text-center active': filters.statusId == '3', 'nav-link text-center': filters.statusId != '3' }" 
       @click.prevent="() => updateFilters({ ...filters, statusId: '3', page: 1 })" href="#" role="tab">
      <img src="../assets/images/done.png" alt="Done" title="Done" class="menu-img ms-2" style="width:2em">
    </a>
  </li>
  <li class="nav-item">
    <a :class="{ 'nav-link text-center active': filters.statusId == '5', 'nav-link text-center': filters.statusId != '5' }" 
       @click.prevent="() => updateFilters({ ...filters, statusId: '5', page: 1 })" href="#" role="tab">
      <img src="../assets/images/on_going.png" alt="On going" title="On going" class="menu-img ms-2" style="width:2em">
    </a>
  </li>
  <li class="nav-item">
    <a :class="{ 'nav-link text-center active': filters.statusId == '4', 'nav-link text-center': filters.statusId != '4' }" 
       @click.prevent="() => updateFilters({ ...filters, statusId: '4', page: 1 })" href="#" role="tab">
      <img src="../assets/images/archived.png" alt="Archived" title="Archived" class="menu-img ms-2" style="width:2em">
    </a>
  </li>
  <li v-if="filters.entity === 'projects' || filters.entity == undefined">
    <a :class="{ 'nav-link text-center active': filters.statusId == '6', 'nav-link text-center': filters.statusId != '6' }" 
       @click.prevent="() => updateFilters({ ...filters, statusId: '6', page: 1 })" href="#" role="tab">
      <img src="../assets/images/potential.png" alt="Potential" title="Potential" class="menu-img ms-2" style="width:2em">
    </a>
  </li>
</ul>

                </div>
                                  <!-- Date Filters -->
                                  <div class="mb-3 d-flex align-items-center">
  <label for="start-date" class="form-label me-2">Start date:</label>
  <input type="date" id="start-date" v-model="startDate" class="form-control w-auto" />
</div>

<div class="mb-3 d-flex align-items-center">
  <label for="end-date" class="form-label me-2">End date:</label>
  <input type="date" id="end-date" v-model="endDate" class="form-control w-auto" />
</div>

<div class="mb-3 d-flex align-items-center">
  <div class="col-md-3">
                    <input type="checkbox" id="expired" name="expired" class=" expired" @change="handleExpiredChange">
                  </div>
                  <div class="col-md-9 col-12">
                    <label for="expired" class="form-label">Ληγμένα - Σε εκκρεμότητα</label>

                  </div>
</div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Toggle Button -->
  <button class="btn btn-toggle-filter" @click="toggleFilters"
        style="position: fixed; right: 1em; top: 40vh; z-index: 1000; padding:10px; background-color: var(--secondary-color)">
        <img src="../assets/images/filter-st.png" alt="Toggle Filters" style="width:2em; cursor: pointer;">
    </button>
</template>

<script>
import { defineComponent, ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useFetchData } from '@/composables/useFetchData';
import {
  fetchAll,
  fetchManagers,
  fetchStatusesForEntity,
  updateProjectStatus,
  updateTaskStatus,
  updateSubtaskStatus,
} from '@/services/followup';
import { useRoute, useRouter } from 'vue-router';
import { mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'FollowUp',
  data() {
        return {
            projectToArchive: null, // Store the project ID to be archived
            isGridView: false, // true for grid view, false for list view
            showFilters: false, // Track if filters are shown
        };
    },
    methods: {
        toggleFilters() {
            this.showFilters = !this.showFilters; // Toggle the filter display
        },
        canEditProject(project) {
            if (!project) { return false; }
            return localStorage.getItem('current_user_id') == project.info?.createdBy && this.userPermissions?.edit === 1;
        },
        openInNewTab(path, id) {
    const url = this.$router.resolve({ path, query: { id } }).href;
    window.open(url, '_blank');
  },
  canEditTask(task) {
            if (!task) { return false; }
            console.log(localStorage.getItem('current_user_id') == task.info?.createdBy)

            return localStorage.getItem('current_user_id') == task.info?.createdBy && this.userPermissionsTasks?.edit === 1;
        },   
        canEditSubTask (subtask) {
            if (!subtask) { return false; }
            return localStorage.getItem('current_user_id') == subtask.info?.createdBy && this.userPermissionsSubTasks?.edit === 1;
        },   
    
  canViewProject(project) {
            if (!project) { return false; }
            return localStorage.getItem('current_user_id') == project.createdBy && this.userPermissions?.view === 1;
        },  
         
  canViewTask(task) {
            if (!task) { return false; }
            return localStorage.getItem('current_user_id') == task.createdBy && this.userPermissions?.view === 1;
        },  
        canAccessProject(projectInfo) {
    return this.superUser || this.canViewAllProjects || this.canViewProject(projectInfo);
  },
  canAccessTask(taskInfo) {
    return this.superUser || this.canViewAllTasks || this.canViewTask(taskInfo);
  },
   // Check if a given date has passed
   isExpired(endDate) {
    if (!endDate) return false; // If no end date, not expired
    const now = new Date();
    const end = new Date(endDate);
    return end < now; // Check if end date is in the past
  },
},
    computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission',

        }),
        superUser() {
            return this.getSuperUserPermission === 1;
        },
        userPermissions() {
            return this.getUserPermission('Projects');
        },
        userPermissionsTasks() {
            return this.getUserPermission('Tasks');
        },
        userPermissionsSubTasks() {
            return this.getUserPermission('Subtasks');
        },
        canEditProjectCreator() {
            return this.userPermissions?.editCreator === 1;
        },
        canEditAllProjects() {
            return this.userPermissions?.editAll === 1;
        },
        canViewAllProjects() {
            return this.userPermissions?.viewAdmin === 1;
        },
        canEditAllTasks() {
            return this.userPermissionsTasks?.editAll === 1;
        },
        canEditAllSubTasks() {
            return this.userPermissionsSubTasks?.editAll === 1;
        },
        canViewAllTasks() {
            return this.userPermissionsTasks?.viewAdmin === 1;
        },
        
        
        canCreateProject() {
            return this.userPermissions?.create === 1;
        },
        canArchive() {
            return this.userPermissions?.archive === 1;
        },
    },
 
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const searchArea = ref(route.query?.searchField || 'title');
    const startDate = ref(route.query?.startDate || '');
    const endDate = ref(route.query?.endDate || '');

    // Watch for changes to startDate and endDate and update URL filters
    watch([searchArea], ([newSearchArea]) => {
      router.push({
        path: '/follow-up',
        query: { ...route.query, searchField: newSearchArea },
      });
    });

    // Watch for changes to startDate and endDate and update URL filters
    watch([startDate, endDate], ([newStartDate, newEndDate]) => {
      router.push({
        path: '/follow-up',
        query: { ...route.query, startDate: newStartDate, endDate: newEndDate },
      });
    });

    

    // Inside the setup() function in your <script> section
    const getPageFromUrl = (url) => {
      if (!url) return null;
      const urlObj = new URL(url, window.location.origin);
      return urlObj.searchParams.get('page');
    };

    // Fetch data and filters
    const { data, paginationLinks, filters, updateFilters, fetchData, count, sortBy } = useFetchData(fetchAll, {
      page: route.query.page || 1,
      active: route.query.active || 1,
      archived: route.query.archived || 0,
      createdByMe: 1,
      entity: route.query.entity || 'all',
      statusId: route.query.statusId || 0,
    });

    // Managers and statuses
    const managers = ref([]);
    const statuses = ref([]);
    const newStatusId = ref(null);

    const getStatusIcon = (id) => {
      const status = statuses.value.find(s => s.id === id);
      return status ? require(`@/assets/images/${status.img}`) : '';
    };

        // Create a reactive variable to track the expired checkbox state
const expired = ref(false);

// Handle expired checkbox change
const handleExpiredChange = (event) => {
  expired.value = event.target.checked;

  // Update filters with expired: true or false based on the checkbox state
  updateFilters({
    ...filters, // Keep existing filters
    expired: expired.value ? 1 : 0, // Add expired filter
    page: 1, // Reset to page 1
  });
};

    // Fetch managers
    const fetchManagersData = async () => {
      try {
        const response = await fetchManagers();
        if (response.data.success) {
          managers.value = response.data.data;
        }
      } catch (error) {
        console.error('Failed to fetch managers:', error);
      }
    };

    // Fetch statuses based on entity
    const fetchStatuses = async () => {
      try {
        let e;
        if (filters.value.entity == 'tasks') {
          e = 'tasks';
        } else if (filters.value.entity == 'subtasks') {
          e = 'subtasks';
        } else if (filters.value.entity == 'projects'){
          e = 'projects';
        }else{
          e = 'all';
        }
        const response = await fetchStatusesForEntity(e);
        statuses.value = response.data.statuses || [];
      } catch (error) {
        console.error('Failed to fetch statuses:', error);
      }
    };

    // Update status directly
    const updateStatus = async (entityId, statusId) => {
      try {
        let response;
        if (filters.value.entity === 'projects') {
          response = await updateProjectStatus(entityId, statusId);
        } else if (filters.value.entity === 'tasks') {
          response = await updateTaskStatus(entityId, statusId);
        } else if (filters.value.entity === 'subtasks') {
          response = await updateSubtaskStatus(entityId, statusId);
        } else {
          response = await updateProjectStatus(entityId, statusId);
        }
        if (response.data.success) {
          toast.success(response.data.message);
          fetchData();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error('Failed to update status:', error);
        toast.error('Failed to update status.');
      }
    };

    // Update manager filter
    const updateManagerFilter = (event) => {
      const newManagerId = event.target.value;
      updateFilters({ ...filters.value, managerFilter: newManagerId, page: 1 });
    };

   

    const getCurrentStatus = (id) => {
      const status = statuses.value.find(s => s.id === id);
      return status ? status.description : 'Select Status';
    };

    // Sort keys
    const sortKeys = ref({
      title: 'projects.description',
      startDate: 'projects.startDate',
      endDate: 'projects.endDate',
      statusId: 'projects.statusId',
    });

    // Watch for changes in entity to update sort keys and statuses
    watch(
      filters,
      (newFilters, oldFilters) => {
        if (newFilters.entity !== oldFilters.entity) {
          let newSortKeys = {
            title: 'projects.description',
            startDate: 'projects.startDate',
            endDate: 'projects.endDate',
            statusId: 'projects.statusId',
          };

          if (newFilters.entity === 'tasks') {
            newSortKeys = {
              title: 'tasks.title',
              startDate: 'tasks.startDate',
              endDate: 'tasks.endDate',
              statusId: 'tasks.statusId',
            };
          } else if (newFilters.entity === 'subtasks') {
            newSortKeys = {
              title: 'subtasks.title',
              startDate: 'subtasks.startDate',
              endDate: 'subtasks.endDate',
              statusId: 'subtasks.statusId',
            };
          }

          sortKeys.value = newSortKeys;
          fetchStatuses(); // Fetch statuses when the entity changes
        }
      },
      { deep: true }
    );

    // Determine entity type for routing
    const getEntityType = (project) => {
    if (filters.value.entity === 'tasks') {
        return 'task';
    } else if (filters.value.entity === 'subtasks') {
        return 'subtask';
    } else if (filters.value.entity === 'projects') {
        return 'project';
    } else {
        // Check project type for unknown entity
        if (project.info?.type === 'subtask') {
            return 'subtask';
        } else if (project.info?.type === 'task') {
            return 'task';
        } else if (project.info?.type === 'project') {
            return 'project';
        } else {
            return 'unknown'; // Default fallback if type is unknown
        }
    }
};


    const searchAction = () => {
      setTimeout(() => {
          router.push({ path: '/follow-up', query: { ...route.query, search: $("#main-search-input").val(), searchField: searchArea.value } });
      }, 300);
    };

    onMounted(() => {
      $("#main-search-input").val(route.query?.search || '');
      $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
      fetchManagersData();
      fetchStatuses();
    });

    onUnmounted(() => {
      $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
    });

    return {
      data,
      paginationLinks,
      filters,
      updateFilters,
      fetchData,
      count,
      sortBy,
      startDate,
      endDate,
      getPageFromUrl,
      managers,
      statuses,
      updateStatus,
      updateManagerFilter,
      getStatusIcon,
      getCurrentStatus,
      sortKeys,
      getEntityType,
      searchArea,
      handleExpiredChange
    };
  },
});
</script>

<style scoped>
.project-code {
  font-weight: bold;
}

.form-select {
  margin-top: 5px;
  width: auto;
}

/* Dropdown styling for status selection */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color:var(--primary-color);
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content button {
  color: black;
  padding: 10px;
  width: 100%;
  border: none;
  cursor: pointer;
  text-align: left;
}

.dropdown-content button:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.current-code{
  font-size:1.1em;
  cursor:pointer;
}

.project-code, .task-code{
   color:#A7A7A7;
   font-size:small;
   cursor:pointer;

}
.expired-row {
  background-color: #ffe6e6; /* Light red background for expired rows */
}

.text-danger {
  color: #dc3545 !important; /* Highlight text in red */
}

.badge.bg-danger {
  background-color: #dc3545; /* Bootstrap danger badge color */
  color: white;
  font-size: 0.75em;
  padding: 0.25em 0.5em;
  border-radius: 0.25rem;
}


</style>
