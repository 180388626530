import axios from 'axios';
import router from '../router';
import loadingStore from '../loading';

// Determine whether we're in development mode
const isDevelopment = process.env.NODE_ENV === 'development';
const apiURL = isDevelopment ? 'http://localhost:8080/backend/api' : `/backend/api`;

const apiClient = axios.create({
  baseURL: apiURL, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to add the API token and show loading spinner
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('api_token'); // Adjust as needed
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Only show loading if noLoading is NOT set to true
    if (!config.noLoading) {
      loadingStore.setLoading(true); // Show loading spinner
    }

    return config;
  },
  (error) => {
    loadingStore.setLoading(false); // Hide loading spinner
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 errors and hide loading spinner
apiClient.interceptors.response.use(
  (response) => {
    // Hide loading spinner if noLoading is not set to true
    if (!response.config.noLoading) {
      loadingStore.setLoading(false);
    }
    return response;
  },
  (error) => {
    loadingStore.setLoading(false); // Hide loading spinner on error
    if (
      error.response &&
      (error.response.status === 401 || error.response.data?.message && error.response.data?.message.indexOf('Unauthenticated') !== -1)
    ) {
      const currentRoute = router.currentRoute.value; // Get the current route

      // Save the current route name and params to sessionStorage
      sessionStorage.setItem('redirectAfterLogin', JSON.stringify({
        name: currentRoute.name,
        params: currentRoute.params,
        query: currentRoute.query
      }));

      // Redirect to the login or home page
      router.push({ name: 'Home' }); // Change 'Home' to your login page if needed
    }

    return Promise.reject(error);
  }
);

export default apiClient;
