<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

  <div class="body d-flex py-lg-3 py-md-2">
    <div class="container-xxl">
      <div class="row clearfix">
        <div class="col-md-12">
          <div class="card border-0 mb-3 no-bg">
            <div class="card-header py-1 px-0 d-md-flex align-items-center justify-content-between main-button-area">
              <div class="date-input ms-3">
                <label for="dateFrom" class="me-4">Από:</label>
                <input class="form-control" type="date" id="dateFrom" v-model="filters.date_from">
                <label for="dateTo" class="ms-2 me-2">Έως:</label>
                <input class="form-control" type="date" id="dateTo" v-model="filters.date_to">
                  <button type="button" class="btn btn-secondary ms-2" @click.prevent="search"><i
                    class="icofont-search-2 me-2 fs-6"></i>Search</button>
              </div>
              <ul class="nav nav-tabs tab-body-header rounded mt-sm-0 mt-3 ms-0 ms-sm-3 prtab-set w-sm-100" role="tablist">
                <li class="nav-item col">
                  <a :class="tabClass(filters.type == 'read')" @click.prevent="updateFilters({ ...filters, type: 'read', page: 1 })" href="#" role="tab">Read</a>
                </li>
                <li class="nav-item col">
                  <a :class="tabClass(filters.type == 'unread' || filters.type == undefined)"
                    @click.prevent="updateFilters({ ...filters, type: 'unread', page: 1 })" href="#" role="tab">Unread</a>
                </li>
                <li class="nav-item col">
                  <a :class="tabClass(filters.type == 'starred')" @click.prevent="updateFilters({ ...filters, type: 'starred', page: 1 })" href="#" role="tab">Starred</a>
                </li>
              </ul>
              
            </div>
          </div>
        </div>
      </div>
      <div class="notifications-list">
        <div v-for="notification in data" :key="notification.updated_at" class="notification-box ms-3">
          <div class="notification-header">
            <span class="mention" v-html="getMentionType(notification.notificationType)"></span>
          </div>
          <div class="notification-body">
            <p v-if="notification.project_title" class="mb-1">
              <strong>Project:</strong>&nbsp;
              <router-link v-if="notification.project_id" :to="{ path: '/project', query: { id: notification.project_id } }">
                {{ notification.project_title }}
              </router-link>
              <span v-else>{{ notification.project_title }}</span>
            </p>
            <p  class="mb-1">
              <strong>Task:</strong>&nbsp;
              
              <span v-if="!notification.task_title">-</span>
              <span v-else><router-link v-if="notification.task_id" :to="{ path: '/task', query: { id: notification.task_id } }">
                {{ notification.task_title }}
              </router-link></span>
            </p>
            <p  class="mb-1">
              <strong>SubTask:</strong>&nbsp;
              <span v-if="!notification.subtask_title">-</span>
              <span v-else><router-link v-if="notification.subtask_id" :to="{ path: '/subtask', query: { id: notification.subtask_id } }">
                {{ notification.subtask_title }}
              </router-link></span>
            </p>
            <p v-if="notification.description" class="mb-2">
              <strong>Περιγραφή:</strong>&nbsp;
              <router-link :to="getLink(notification.notificationType)">
                <span v-html="notification.description"></span>
              </router-link>
            </p>
            
          </div>
          <div class="notification-footer mt-2">
            <div style="margin-left:1rem">
                <p v-if="notification.username" class="mb-1">
              Από τον/την:<strong>&nbsp;{{ notification.username }}</strong>
            </p>
            <p class="mb-2">
              <strong></strong><img src="../assets/images/diary.png" alt="Edit" title="Edit" class="menu-img" style="width:1.5em">&nbsp;{{ notification.created_at }}
            </p>
              </div>
               <!-- Action Buttons -->
           <div class="action-buttons">
            <button class="btn btn-dark" @click="toggleStarNotification(notification)">
                <span v-if="notification.isStarred == 1" class="fa fa-star checked menu-img"></span>

              <span v-else class="fa fa-star menu-img"></span>

            </button>
            <button class="btn btn-dark" @click="toggleReadNotification(notification)">
              <i v-if="notification.isRead == 1" class="fa fa-envelope-open menu-img"></i>

              <i v-else class="fa fa-envelope menu-img"></i>

            </button>
            <button class="btn btn-dark delete" @click="confirmDelete(notification.id)">
            <img src="../assets/images/delete.png" alt="Archive" title="Archive" class="menu-img" style="width:1.5em">
          </button>
           </div>    
            
                                                                                                                                                                                                                                                                                                                                                                                                                   
          </div>
        </div>
        <div v-if="!data || !data.length">
          <p>No data</p>
        </div>
      </div>
      <div class="row" v-if="data && data.length">
        <nav aria-label="Page navigation">
          <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
            <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
              <router-link :to="{ path: 'notifications', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                <span class="page-link" v-html="paginationLink.label"></span>
              </router-link>
              <span class="page-link" v-else v-html="paginationLink.label"></span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

   <!-- Delete Confirmation Modal -->
   <div v-if="showDeleteModal" class="modal-overlay">
    <div class="modal-content">
      <h5>Are you sure you want to delete this notification?</h5>
      <div class="modal-actions">
        <button class="btn btn-secondary" @click="showDeleteModal = false">Cancel</button>
        <button class="btn btn-danger" @click="deleteNotification(confirmationNotificationId)">Delete</button>

      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useFetchData } from '@/composables/useFetchData';
import { useToast } from 'vue-toastification';
import { fetchAll, fetchManagers, marked, starNotificationById, deleteNotificationById, unstarNotificationById, markNotificationReadById, markNotificationUnreadById } from '@/services/notifications';
import Editor from '@tinymce/tinymce-vue';
import Select2 from 'vue3-select2-component';

export default defineComponent({
  name: 'Notifications',
  components: {
    Editor,
    Select2
  },
 
  setup() {
    const { data, paginationLinks, filters, updateFilters, fetchData, count } = useFetchData(fetchAll, { page: 1, type: 'unread' }, { page: 1, type: 'unread', date_from: null, date_to: null });

    const search = () => {
      updateFilters({ ...filters.value, page: 1 });
    };

    // State to control modal visibility and store notification ID
    const showDeleteModal = ref(false);
    const confirmationNotificationId = ref(null);

    // Function to open modal and set ID of notification to delete
    const confirmDelete = (notificationId) => {

      showDeleteModal.value = true;
      console.log(showDeleteModal.value); // Add this line to debug

      confirmationNotificationId.value = notificationId;
    };

    // Function to delete notification
    const deleteNotification = async (notificationId) => {
      try {
        const resp = await deleteNotificationById(notificationId);
        useToast().success(resp.data.message[0]);

        showDeleteModal.value = false;
        fetchData(); // Call function to refresh the data after deletion
      } catch (error) {
        console.error('Error deleting notification:', error);
      }
    };

    const getPageFromUrl = (url) => {
      if (!url) return null;
      const urlObj = new URL(url, window.location.origin);
      return urlObj.searchParams.get('page');
    };

    const tabClass = (isActive) => ({
      'nav-link': true,
      'text-center': true,
      'active': isActive
    });

    const getMentionType = (notificationType) => {
  const formatMention = (prefix, suffix) => `<strong>${prefix}</strong><span style="font-weight: 700;"> ${suffix}</span>`;

  switch (notificationType) {
    case 'DAYS_OFF_REQUEST_ACCEPTED':
      return formatMention('DAYS OFF ▶', 'ACCEPTED');
    case 'DAYS_OFF_REQUEST_CREATION':
      return formatMention('DAYS OFF ▶', 'CREATION');
    case 'DAYS_OFF_REQUEST_REJECTED':
      return formatMention('DAYS OFF ▶', 'REJECTED');
    case 'HOURS_REQUEST_ACCEPTED':
      return formatMention('HOURS ▶', 'ACCEPTED');
    case 'HOURS_REQUEST_CREATION':
      return formatMention('HOURS ▶', 'CREATION');
    case 'HOURS_REQUEST_REJECTED':
      return formatMention('HOURS ▶', 'REJECTED');
    case 'MANAGER_ASSIGNMENT_PROJECT':
      return formatMention('ASSIGNMENT ▶', 'PROJECT MANAGER');
    case 'MANAGER_ASSIGNMENT_SUBTASK':
      return formatMention('ASSIGNMENT ▶', 'SUBTASK MANAGER');
    case 'MANAGER_ASSIGNMENT_TASK':
      return formatMention('ASSIGNMENT ▶', 'TASK MANAGER');
    case 'PROJECT_ASSIGNMENT':
      return formatMention('ASSIGNMENT ▶', 'PROJECT');
    case 'PROJECT_COMMENT_MENTION':
      return formatMention('PROJECT ▶', 'MENTION');
    case 'PROJECT_CREATOR_STATUS_CHANGES':
      return formatMention('PROJECT ▶', 'STATUS CHANGE');
    case 'PROJECT_MANAGER_EDIT':
      return formatMention('PROJECT ▶', 'CHANGE');
    case 'PROJECT_MANAGER_STATUS CHANGES':
      return formatMention('PROJECT ▶', 'STATUS CHANGE');
    case 'SUBTASK_ASSIGNMENT':
      return formatMention('ASSIGNMENT ▶', 'SUBTASK');
    case 'SUBTASK_COMMENT_MENTION':
      return formatMention('SUBTASK ▶', 'MENTION');
    case 'SUBTASK_CREATOR_STATUS_CHANGES':
      return formatMention('SUBTASK ▶', 'STATUS CHANGE');
    case 'SUBTASK_MANAGER_EDIT':
      return formatMention('SUBTASK ▶', 'CHANGE');
    case 'SUBTASK_MANAGER_STATUS_CHANGES':
      return formatMention('SUBTASK ▶', 'STATUS CHANGE');
    case 'TASK_ASSIGNMENT':
      return formatMention('ASSIGNMENT ▶', 'TASK');
    case 'TASK_COMMENT_MENTION':
      return formatMention('TASK ▶', 'MENTION');
    case 'TASK_CREATOR_STATUS_CHANGES':
      return formatMention('TASK ▶', 'STATUS CHANGE');
    case 'TASK_MANAGER_EDIT':
      return formatMention('TASK ▶', 'CHANGE');
    case 'TASK_MANAGER_STATUS_CHANGES':
      return formatMention('TASK ▶', 'STATUS CHANGE');
    case 'MISSING_HOURS_DAYS':
      return formatMention('HOURS ▶', 'MISSING');
    case 'POTENTIAL_CLIENT_REMINDER':
      return formatMention('CLIENT ▶', 'POTENTIAL REMINDER');
    case 'POTENTIAL_PROJECT_REMINDER':
      return formatMention('PROJECT ▶', 'POTENTIAL REMINDER');
    case 'WRONG_HOSTING_EXPIRATION_DATA':
      return formatMention('HOSTING ▶', 'WRONG EXPIRATION DATA');
    case 'HOSTING_WILL_EXPIRE':
      return formatMention('HOSTING ▶', 'WILL EXPIRE');
    case 'HOSTING_EXPIRED':
      return formatMention('HOSTING ▶', 'EXPIRED');
    case 'PROJECT_MANAGER_STATUS_CHANGES':
      return formatMention('PROJECT ▶', 'STATUS CHANGE');
    case 'EXPIRED_PROJECT_REMINDER':
      return formatMention('PROJECT ▶', 'EXPIRED');
    case 'EXPIRED_TASK_REMINDER':
      return formatMention('TASK ▶', 'EXPIRED');
    case 'EXPIRED_SUBTASK_REMINDER':
      return formatMention('SUBTASK ▶', 'EXPIRED');
    default:
      return '-';
  }
};


    const getLink = (notificationType) => {
      switch (notificationType) {
        case 'DAYS_OFF_REQUEST_ACCEPTED':
        case 'DAYS_OFF_REQUEST_REJECTED':
          return '/days-off';
        case 'DAYS_OFF_REQUEST_CREATION':
          return '/days-off-requests';
        case 'HOURS_REQUEST_CREATION':
          return '/hours-requests';
        case 'HOURS_REQUEST_ACCEPTED':
        case 'HOURS_REQUEST_REJECTED':
          return '/hours';
        case 'MISSING_HOURS_DAYS':
          return '/missing-hours';
        default:
          return '#';
      }
    };

    const starNotification = async (notificationId) => {
      try {
        const resp = await starNotificationById(notificationId);
        useToast().success(resp.data.message[0]);
      } catch (error) {
        console.error('Error starring notification:', error);
      }
    };

    const unstarNotification = async (notificationId) => {
      try {
        const resp = await unstarNotificationById(notificationId);
        useToast().success(resp.data.message[0]);
      } catch (error) {
        console.error('Error unstarring notification:', error);
      }
    };

    const toggleStarNotification = async (notification) => {
      if (notification.isStarred) {
        await unstarNotification(notification.id);
        notification.isStarred = 0;  // Update local state
      } else {
        await starNotification(notification.id);
        notification.isStarred = 1;  // Update local state
      }
      notification.updated_at = Date.now(); // Force re-render by changing the key
      fetchData();
    };

    const markNotificationRead = async (notificationId) => {
      try {
        const resp = await markNotificationReadById(notificationId);
        useToast().success(resp.data.message[0]);
      } catch (error) {
        console.error('Error marking notification as read:', error);
      }
    };

    const markNotificationUnread = async (notificationId) => {
      try {
        const resp = await markNotificationUnreadById(notificationId);
        useToast().success(resp.data.message[0]);
      } catch (error) {
        console.error('Error marking notification as unread:', error);
      }
    };

    const toggleReadNotification = async (notification) => {
      if (notification.isRead) {
        await markNotificationUnread(notification.id);
        notification.isRead = 0;  // Update local state
      } else {
        await markNotificationRead(notification.id);
        notification.isRead = 1;  // Update local state
      }
      notification.updated_at = Date.now(); // Force re-render by changing the key
      await fetchData();
    };

    return {
      search,
      data,
      paginationLinks,
      filters,
      updateFilters,
      fetchData,
      getMentionType,
      getLink,
      tabClass,
      getPageFromUrl,
      starNotification,
      deleteNotification,
      unstarNotification,
      toggleStarNotification,
      markNotificationRead,
      markNotificationUnread,
      toggleReadNotification,
      confirmDelete,
      showDeleteModal,
      confirmationNotificationId
    };
  },
  metaInfo: {
    title: 'Notifications',
    meta: [
      { name: 'description', content: 'MIS - Notifications' },
      { property: 'og:title', content: 'MIS - Notifications' },
      { property: 'og:description', content: 'This is the notifications page.' }
    ]
  }
});

</script>

<style scoped>
.body {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.container-xxl {
  width: 100%;
}

.card {
  border: none;
  margin-bottom: 1rem;
}

.main-button-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-tabs{
    border:none;
}

/* Style for all list items to have a bottom border */
.nav-tabs .nav-item {
  border-bottom: 1px solid #DCDCDC; /* Light gray border for non-active items */
  margin-left:20px;
}

.nav-tabs .nav-item .nav-link {
  color:#8C8C8C;
}

/* Style for the active tab to have a darker bottom border */
.nav-tabs .nav-item .nav-link.active {
  border-bottom: 3px solid #8C8C8C; /* Darker gray border for the active tab */
  color:black;
  background-color: white;
}

.search-input {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.date-input {
  display: flex;
  align-items: center;
}

.date-input label {
  margin-right: 0.5rem;
}

.notifications-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  width: 100%;
}

.notification-box {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.notification-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mention {
  color: var(--secondary-color);
  font-weight: 700;
  font-size: larger;
}

.notification-icons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;

}

.notification-icons button {
  display: flex;
  align-items: center;
  justify-content: center;
}



.notification-icons p {
  margin-left:1rem;
}

.notification-body p {
  margin: 0.5rem 0;
  padding-left: 1rem;
}

.icon-star,
.icon-envelope {
  font-size: 1.8em;
  cursor: pointer;
  color:var(--secondary-color);
}




.fa-star, .fa-envelope, .fa-envelope-open {
    font-size: 1.3rem;
    color: white;
    text-shadow: 
        1px 1px 0 var(--secondary-color),  /* Right shadow */
       -1px 1px 0 var(--secondary-color),  /* Left shadow */
        1px -1px 0 var(--secondary-color), /* Bottom shadow */
       -1px -1px 0 var(--secondary-color); /* Top shadow */
}

.checked  {
  color: #E4C31D;
  text-shadow: 
        0px 0px 0 var(--secondary-color),  /* Right shadow */
        0px 0px 0 var(--secondary-color),  /* Left shadow */
        0px 0px 0 var(--secondary-color), /* Bottom shadow */
        0px 0px 0 var(--secondary-color); /* Top shadow */
}
.notification-box {
  position: relative; /* Makes the action-buttons position relative to this container */
  padding-right: 3rem; /* Adds space for the buttons to overlap slightly */
  margin-right:5px;
}

.action-buttons {
  position: absolute;
  top: 50%;
  right: -0.1rem; /* Adjusts position so that buttons are partially outside */
  transform: translateY(-50%); /* Centers the buttons vertically */
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Adds spacing between the buttons */
}

.action-buttons .btn {
  width: 2rem; /* Adjust the width to make buttons smaller if needed */
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}
.modal-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

</style>
