import { createRouter, createWebHistory } from 'vue-router';
import Login from './views/Login.vue';
import Dashboard from './views/Dashboard.vue';
import ResetPassword from './views/ResetPassword.vue';
import { fetchUserInfo } from './services/user';
import Users from './views/Users.vue';
import Services from './views/Services.vue';
import Clients from './views/Clients.vue';
import Suppliers from './views/Suppliers.vue';
import Register from './views/Register.vue';
import Groups from './views/Groups.vue';
import Projects from './views/Projects.vue';
import Hosting from './views/Hosting.vue';
import Holidays from './views/Holidays.vue';
import DaysOff from './views/DaysOff.vue';
import DaysOffRequests from './views/DaysOffRequests.vue';
import MissingHours from './views/MissingHours.vue';
import Project from './views/Project.vue';
import Host from './views/Host.vue';
import Task from './views/Task.vue';
import SubTask from './views/SubTask.vue';
import HoursRequests from './views/HoursRequests.vue';
import ToDo from './views/ToDo.vue';
import FollowUp from './views/FollowUp.vue';
import Notifications from './views/Notifications.vue';
import Hours from './views/Hours.vue';
import DaysOffReports from './views/DaysOffReports.vue';
import ContactsClients from './views/ContactsClients.vue';
import Contacts from './views/Contacts.vue';
import ContactsSuppliers from './views/ContactsSuppliers.vue';
import OrphanedContacts from './views/OrphanedContacts.vue';
import ChangeProfile from './views/ChangeProfile.vue';
import NotificationSettings from './views/NotificationSettings.vue';
import Reports from './views/Reports.vue';
import EditDashboard from './views/EditDashboard.vue';
import BoxIntegration from './views/BoxIntegration.vue';
import HistoryChanges from './views/HistoryChanges.vue';
import MyPermissions from './views/MyPermissions.vue';


const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { layout: 'MainLayout' }
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { layout: 'LoginLayout' }
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
    meta: { layout: 'ResetPasswordLayout' }
  },
  {
    path: '/notification-settings',
    name: 'Notification Settings',
    component: NotificationSettings,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: { layout: 'MainLayout' }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      layout: 'NoLayout' // No layout for Register component
    },
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta: {
      layout: 'MainLayout'
    },
  },
  {
    path: '/service-categories',
    name: 'Groups',
    component: Groups,
    meta: {
      layout: 'MainLayout'
    },
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,
    meta: {
      layout: 'MainLayout'
    },
  },  
  {
    path: '/suppliers',
    name: 'Suppliers',
    component: Suppliers,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/contacts-clients',
    name: 'ContactsClients',
    component: ContactsClients,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/contacts-suppliers',
    name: 'ContactsSuppliers',
    component: ContactsSuppliers,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/orphaned-contacts',
    name: 'OrphanedContacts',
    component: OrphanedContacts,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/hosting',
    name: 'Hosting',
    component: Hosting,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/project',
    name: 'Project',
    component: Project,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/host',
    name: 'Host',
    component: Host,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/holidays',
    name: 'Holidays',
    component: Holidays,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/days-off',
    name: 'DaysOff',
    component: DaysOff,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/days-off-requests',
    name: 'DaysOffRequests',
    component: DaysOffRequests,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/missing-hours',
    name: 'MissingHours',
    component: MissingHours,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/task',
    name: 'Task',
    component: Task,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/subtask',
    name: 'SubTask',
    component: SubTask,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/hours-requests',
    name: 'HoursRequests',
    component: HoursRequests,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/to-do',
    name: 'ToDo',
    component: ToDo,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/follow-up',
    name: 'FollowUp',
    component: FollowUp,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/hours',
    name: 'Hours',
    component: Hours,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/days-off-reports',
    name: 'Days Off Reports',
    component: DaysOffReports,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/task',
    name: 'Task',
    component: Task,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/change-profile',
    name: 'ChangeProfile',
    component: ChangeProfile,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/edit-dashboard',
    name: 'Edit Dashboard',
    component: EditDashboard,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/box-integration',
    name: 'Box Integration',
    component: BoxIntegration,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/history-changes',
    name: 'History Changes',
    component: HistoryChanges,
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/my-permissions',
    name: 'My Permissions',
    component: MyPermissions,
    meta: {
      layout: 'MainLayout'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach( async (to, from, next) => {
    // Meta tags population
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    if (nearestWithMeta) {
      nearestWithMeta.meta.metaTags.forEach(tag => {
        const element = document.createElement('meta');
        Object.keys(tag).forEach(key => {
          element.setAttribute(key, tag[key]);
        });
        document.head.appendChild(element);
      });
    }

    // Auth guard
    const publicPages = ['Login', 'Reset Password', 'Register'];
    const authRequired = !publicPages.includes(to.name);
    const token = localStorage.getItem('api_token');
  
    if (authRequired) {
      try {
        const userInfo = await fetchUserInfo();
        if (!userInfo || userInfo.data.info == null) { throw new Error('Invalid/expired token'); }
        next();
      } catch (error) {
        localStorage.removeItem('api_token');
        next({ name: 'Login' });
      }
    } else {
      if (token) {
        try {
          const userInfo = await fetchUserInfo();
          if (!userInfo || userInfo.data.info == null) { throw new Error('Invalid/expired token'); }
          if (to.name === 'Login') {
            next({ name: 'Dashboard' });
          } else {
            next();
          }
        } catch (error) {
          localStorage.removeItem('api_token');
          if (to.name !== 'Login') {
            next({ name: 'Login' });
          }
        }
      } else {
        next();
      }
    }
  });

export default router;
