<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row g-3">
                <!-- Left Column with Tabs -->
                <div class="col-xl-8 col-lg-7 col-md-12" style="border-right:lightgray 2px solid;
    padding-right: 10px;">
                    <!-- Project Header with Custom Status Dropdown -->
                    <div class="d-flex align-items-center justify-content-start mb-1">
  
    
    <div class="d-inline-flex align-items-center ms-2">
        <!-- Circle with 'P' -->
        <span class="project-circle">P</span>

        <!-- Pipe separator -->
        <span class="ms-2" style="font-size:1.5rem">|</span>

         <!-- Avatar image, only shown if projectViewData and projectViewData.client exist -->
         <img v-if="projectViewData && projectViewData.client?.avatar_file_name"
             :src="`/storage/clients_avatars/${projectViewData.client.avatar_file_name}`"
             style="width:10em">

        <span v-else>
            <span v-if="projectViewData && projectViewData.client?.title" style="font-size:1.2rem">{{ projectViewData.client.title }}</span>
        </span> 
    </div>
</div>

                        <div class="d-flex align-items-center justify-content-start mb-1">

                        <h4 class="project-code ms-2" v-if="projectViewData">{{ projectViewData.info?.code }}</h4>

                        <!-- Custom Status Dropdown -->
                        <div class="status-dropdown ms-3">
                            <button class="status-button justify-content-center" v-if="projectViewData" :style="getStatusStyle(projectViewData.status?.id)">
                                <i :class="getStatusIcon(projectViewData.status?.id)" v-if="canEditProject(projectViewData) || canEditAllProjects || superUser"></i>
                                {{ getCurrentStatus(projectViewData.status?.id) }}
                                <i class="fa fa-caret-down" v-if="canEditProject(projectViewData) || canEditAllProjects || superUser"></i>
                            </button>

                            <!-- Dropdown Content (Visible on Hover) -->
                            <div class="status-dropdown-content" v-if="canEditProject(projectViewData) || canEditAllProjects || superUser" >
                                <button v-if="projectViewData" v-for="status in statuses" :key="status.id" @click="selectStatus(projectViewData.info?.id, status.id)"  :style="getStatusStyle(status.id)"
                                    :class="{ 'active-status': projectViewData.status?.id === status.id }">
                                    <i :class="status.icon" ></i>
                                    {{ status.description }}
                                </button>
                            </div>
                        </div>
                        <!-- Button Positioned Over Border -->
                        <div class="status-button-wrapper">
                           

                            <button class="btn btn-dark" v-if="!isEditingInfo && activeTab === 'info' && (superUser || canEditAllProjects || canEditProject(projectViewData))"
                                    @click="editProject">
                                    <img src="../assets/images/edit.png" alt="Edit" title="Edit" class="menu-img"
                                        style="width:2em">
                                </button>

                                <div v-if="isEditingInfo && activeTab === 'info'" class="editing-actions">
                                    <!-- Save Button -->
                                    <button class="btn btn-secondary mb-3" @click="saveInfo">
                                        <img src="../assets/images/save.png" alt="Save" title="Save" class="menu-img"
                                            style="width:2em">
                                    </button>

                                    <!-- Cancel Button -->
                                    <button class="btn btn-danger mt-2" @click="cancelEditing">
                                        <img src="../assets/images/cancel.png" alt="Cancel" title="Cancel"
                                            class="menu-img" style="width:2em">
                                    </button>
                                </div>


                                   <!-- Edit Button Manager-->
                                   <button class="btn btn-dark"
                                    v-if="!isEditingManagersBrief && activeTab === 'managerBrief' && (projectViewData.info.managerId == currentUserInfo?.id || superUser || canEditAllProjects)"
                                    @click="editProjectManager">
                                    <img src="../assets/images/edit.png" alt="Edit" title="Edit" class="menu-img"
                                        style="width:2em">
                                </button>

                                <!-- Save and Cancel Buttons -->
                                <div v-if="isEditingManagersBrief && activeTab === 'managerBrief' && (projectViewData.info.managerId == currentUserInfo?.id || superUser || canEditAllProjects)"
                                    class="editing-actions">
                                    <!-- Save Button -->
                                    <button class="btn btn-secondary mb-3" @click="saveManager">
                                        <img src="../assets/images/save.png" alt="Save" title="Save" class="menu-img"
                                            style="width:2em">
                                    </button>

                                    <!-- Cancel Button -->
                                    <button class="btn btn-danger mt-2" @click="cancelEditingManager">
                                        <img src="../assets/images/cancel.png" alt="Cancel" title="Cancel"
                                            class="menu-img" style="width:2em">
                                    </button>
                                </div>
                        </div>
                    </div>

                    <!-- Tabs -->
                    <ul class="nav nav-tabs mb-3" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'info' }"
                                @click.prevent="activeTab = 'info'" href="#">Info</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'managerBrief' }"
                                @click.prevent="activeTab = 'managerBrief'" href="#">Manager's Brief</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'tasks' }"
                                @click.prevent="activeTab = 'tasks'" href="#">Tasks</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'projectChat' }"
                                @click.prevent="activeTab = 'projectChat'" href="#">Project Chat</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'files' }"
                                @click.prevent="activeTab = 'files'" href="#">Files</a>
                        </li>
                    </ul>

                    <!-- Tab Content -->
                    <div class="tab-content">
                        <!-- Info Tab -->
                        <div v-if="activeTab === 'info'" class="tab-pane fade show active">
                           
                            <div class="card">
                                <div class="card-body" v-if="!isEditingInfo">
                                    <div v-html="projectViewData.info?.body" v-if="projectViewData"></div>
                                </div>
                                <div class="card-body" v-if="isEditingInfo">
                                    <div class="mb-3" v-if="projectViewData">
                                        <Editor api-key="no-api-key" :init="tinymceInit"
                                            v-model="projectViewData.info.body"></Editor>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="activeTab === 'files'" class="tab-pane fade show active">
                            <div class="card">
                                <div class="card-body">
                                    <iframe :src="projectViewData.info?.shared_link" style="width: 100%; height: 500px;"></iframe>
                                </div>
                            </div>
                        </div>

                        <!-- Manager's Brief Tab -->
                        <div v-if="activeTab === 'managerBrief'" class="tab-pane fade show active">

                            <div class="card">
                                <div class="card-body" v-if="!isEditingManagersBrief">
                                    <div v-html="projectViewData.info?.manager_brief" v-if="projectViewData"></div>
                                </div>
                                <div class="card-body" v-if="isEditingManagersBrief">
                                    <div class="mb-3" v-if="projectViewData">
                                        <Editor api-key="no-api-key" :init="tinymceInit"
                                            v-model="projectViewData.info.manager_brief"></Editor>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Tasks Tab -->
                        <div v-if="activeTab === 'tasks'" class="tab-pane fade show active">
                            <div class="card">
                                <div class="card-body table-responsive">
                                    <table id="myProjectTable" class="table table-hover align-middle mb-0 mis_table"
                                        style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>Task <i class="fa fa-sort sorting-icon" @click.prevent="sortBy('tasks.title')"></i></th>
                                                <th>
                                                <div v-if="superUser" class="d-flex align-items-center">
                                                    <select class="ms-1 nav-link rounded" name="" id="dropdownManagers"
                                                        @change="updateManagerFilter">
                                                        <option value="" selected>MANAGER</option>
                                                        <option v-for="manager in managers" :key="manager.id"
                                                            :value="manager.id">
                                                            {{ manager.username }}
                                                        </option>
                                                    </select>
                                                    <img src="../assets/images/icons8-superman.svg" alt="Citrine Logo"
                                                        class="menu-img ms-1" style="width:2em">
                                                </div>
                                                <div v-else>
                                                    Manager
                                                    <i class="fa fa-sort sorting-icon" @click.prevent="sortBy('tasks.managerId')"></i>
                                                </div>
                                            </th>
                                                <th>Start Date<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('tasks.startDate')"></i></th>
                                                <th>End Date<i class="fa fa-sort sorting-icon" @click.prevent="sortBy('tasks.endDate')"></i></th>
                                                <th>Status <i class="fa fa-sort sorting-icon" @click.prevent="sortBy('tasks.statusId')"></i></th>
                                                <th  class="no-after"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(task, index) in data" :key="task.info.id">
                                                <td>
                                                    <router-link :to="{ path: 'task', query: { id: task.info?.id } }">
                                                        {{ task.info?.title }}
                                                    </router-link>
                                                </td>
                                                <td>
                                                    <img v-if="task.manager?.avatar_file_name"
                                                        :src="`/storage/avatars/${task.manager.avatar_file_name}`" width="35" :title="task.manager.username">
                                                    <span v-else> {{ task.manager?.username }} </span>    </td>
                                                <td>{{ task.info?.startDate }}</td>
                                                <td>{{ task.info?.endDate }}</td>
                                                <td>
                                                    <!-- Custom Status Dropdown for Each Task -->
                                                    <div class="status-dropdown">
                                                        <button class="status-button" :style="getStatusStyle(task.status?.id)">
                                                            <i :class="getStatusIcon(task.status.id)"></i>
                                                            {{ getCurrentStatus(task.status.id) }}
                                                            <i class="fa fa-caret-down" v-if="canEditProject(projectViewData) || canEditAllProjects || superUser"></i>
                                                        </button>
                                                        <div class="status-dropdown-content" v-if="canEditProject(task) || canEditAllProjects || superUser">
                                                            <button v-for="status in task_statuses" v-if="status?.id != 6" :key="status.id" :style="getStatusStyle(status?.id)"
                                                                @click="updateTaskStatusId(task.info, status.id)"
                                                                :class="{ 'active-status': task.status.id === status.id }">
                                                                <i :class="status.icon" ></i>
                                                                {{ status.description }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                               
                                                <td>
                                                <div class="button-group">
                                                    <div class="icon-wrapper">
                                                        <button class="btn btn-dark">
                                                            <img src="../assets/images/chat.png" alt="Unread comments"
                                                                title="Unread comments" class="menu-img"
                                                                style="width:1.5em">
                                                            <span class="unread-comments"
                                                                v-if="task.unread_comments > 0">{{
                                                                task.unread_comments }}</span>
                                                        </button>
                                                    </div>
                                                    <div class="icon-wrapper">
                                                        <button class="btn btn-dark"
                                                            @click.prevent="editTask(task.info.id)"
                                                            v-if="canEditTask(task) || canEditAllTasks || superUser">
                                                            <img src="../assets/images/edit.png" alt="Edit" title="Edit"
                                                                class="menu-img" style="width:1.5em">
                                                        </button>
                                                    </div>
                                                    <div class="icon-wrapper">
                                                        <button class="btn btn-dark"
                                                            @click.prevent="openArchiveConfirmation(task.info?.id)"
                                                            v-if="canArchiveTask || superUser">
                                                            <img src="../assets/images/delete.png" alt="Archive"
                                                                title="Archive" class="menu-img" style="width:1.5em">
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>

                                            </tr>
                                            <tr v-if="!data || !data.length">
                                                <td colspan="6" class="text-center">No tasks found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav aria-label="Page navigation">
                                        <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                                            <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                                                <router-link :to="{ path: 'project', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                                                    <span class="page-link" v-html="paginationLink.label"></span>
                                                </router-link>
                                                <span class="page-link" v-else v-html="paginationLink.label"></span>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <!-- Project Chat Tab -->
                        <div v-if="activeTab === 'projectChat'" class="tab-pane fade show active">
    <div class="card">
        <div class="card-body">
            <!-- Chat History List -->
            <ul ref="chatHistory" class="chat-history list-unstyled mb-3">
                <li
                    class="mb-3 d-flex"
                    v-if="projectViewData && projectViewData.comments"
                    v-for="comment in projectViewData.comments"
                    :key="comment.id"
                    :class="{
                        'justify-content-end': comment.author?.id == currentUserId,
                        'justify-content-start': comment.author?.id != currentUserId
                    }"
                >
                    <!-- Author's Info and Message -->
                    <div style="width:50%;"
                        
                    >
                        <span class="text-muted small d-flex align-items-center" :class="{
                            'justify-content-end': comment.author?.id == currentUserId,
                            'justify-content-start': comment.author?.id != currentUserId
                        }">
                            <img
                                v-if="comment.author?.avatar_file_name"
                                :src="`/storage/avatars/${comment.author?.avatar_file_name}`"
                                width="25"
                                class="me-2"
                            />
                            <h6 class=fw-bolder>{{ comment.author.username }}</h6>
                        </span>
                        <div
                            class="chat-message p-2 mt-1 rounded"
                            
                        >
                            {{ comment.info?.comment }}
                        </div>
                        <span class="text-muted small">
                            <i class="icofont icofont-date"></i><img src="../assets/images/diary.png" alt="Edit" title="Edit" class="menu-img" style="width:1.5em">{{ comment.info.created_at }}
                        </span>
                    </div>
                </li>
            </ul>

            <!-- Message Input Section -->
            <div class="d-flex">
    <textarea
        id="commentTextarea"
        class="form-control position-relative"
        placeholder="Αποστολή Μηνύματος"
        v-model="newComment"
        @input="filterUsernames"
    ></textarea>

    <!-- Autocomplete Dropdown -->
    <div
        v-if="showAutocomplete"
        class="autocomplete-dropdown"
        :style="{ top: `${dropdownTop}px`, left: `${dropdownLeft}px` }"
    >
        <ul class="list-unstyled m-0 p-2">
            <li
                v-for="user in filteredUsernames"
                :key="user.id"
                @click="selectUsername(user.username)"
                class="autocomplete-item"
            >
                {{ user.username }}
            </li>
        </ul>
    </div>
    <button @click.prevent="submitComment" class="btn sendComment mt-2">
        <i class="fa fa-paper-plane"></i>
    </button>
</div>

        </div>
    </div>
</div>

                    </div>
                </div>

                <!-- Right Column with Project Details -->
                <div class="col-xl-4 col-lg-5 col-md-12" v-if="activeTab != 'tasks' && projectViewData" >
                    <div class="card">
                        <div class="card-body">
                            <button class="btn btn-dark" v-if="!isEditingGeneral && (superUser || canEditAllProjects || canEditProject(projectViewData))" @click="editProjectGeneral"
                                style="position: absolute; top: 25px; right: 10px;">
                                <img src="../assets/images/edit.png" alt="Edit" title="Edit" class="menu-img"
                                    style="width: 2em;">
                            </button>

                            <!-- Save Button -->
                            <button class="btn btn-secondary mb-3" @click="saveGeneral"
                                v-if="isEditingGeneral"
                                style="position: absolute; top: 25px; right: 10px;">

                                <img src="../assets/images/save.png" alt="Save" title="Save" class="menu-img"
                                    style="width:2em">
                            </button>

                            <!-- Cancel Button -->
                            <button class="btn btn-danger mt-2" @click="cancelEditingGeneral"
                                v-if="isEditingGeneral"
                                style="position: absolute; top: 65px; right: 10px;">

                                <img src="../assets/images/cancel.png" alt="Cancel" title="Cancel" class="menu-img"
                                    style="width:2em">
                            </button>

                            <div class="row" v-if="!isEditingGeneral">                                <div class="col-12"><img src="../assets/images/diary.png" alt="Edit" title="Edit" class="menu-img" style="width:1.5em">
                                    <strong>Start Date:</strong> {{ projectViewData.info.startDate }}</div>
                                <div class="col-12"><strong><img src="../assets/images/diary.png" alt="Edit" title="Edit" class="menu-img" style="width:1.5em">End Date:</strong> {{ projectViewData.info.endDate }}</div>
                                <br/><br/>
                                <div class="col-12"><strong>Creator:</strong> <img v-if="projectViewData?.creator?.avatar_file_name" :src="`/storage/avatars/${projectViewData?.creator?.avatar_file_name}`" width="35" :title="projectViewData.creator.username"/></div>
                                <div class="col-12"><strong>Manager:</strong> <img v-if="projectViewData?.manager?.avatar_file_name" :src="`/storage/avatars/${projectViewData?.manager?.avatar_file_name}`" width="35" :title="projectViewData.manager.username"/></div>
                                <div style="display: inline-flex;">
                                    <div class="col-12"><strong>Assigned Users:</strong>
                                        <div v-for="user in projectViewData.assigned" :key="user.id"
                                            style="display: inline-flex; align-items: center; margin-right: 10px;">
                                            <img v-if="user?.avatar_file_name"
                                                :src="`/storage/avatars/${user.avatar_file_name}`" width="25" :title="user.username"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12"><strong>Client:</strong> {{ projectViewData.client.nickname }}</div>
                                <div class="col-12"><strong>Service:</strong> {{ projectViewData.service.title }}</div>
                                <div class="col-12"><strong>Payable:</strong> {{ projectViewData.info.payable ? "Yes" : "No" }}</div>
                                <div class="col-12"><strong>Paid:</strong> {{ projectViewData.info.paid ? "Yes" : "No" }}</div>
                                <div class="col-12"><strong>Invoiced:</strong> {{ projectViewData.info.invoiced ? "Yes" : "No" }}</div>
                            </div>

                            <div class="row" v-if="isEditingGeneral">
  <!-- Start Date -->
  <div class="col-8 d-flex align-items-center mb-3">
    <strong class="me-2" style="width: 150px; text-align: right;">Start Date:</strong>
    <input 
      type="date" 
      class="form-control flex-grow-1" 
      v-model="projectData.startDate" 
    />
  </div>
  
  <!-- End Date -->
  <div class="col-8 d-flex align-items-center mb-3">
    <strong class="me-2" style="width: 150px; text-align: right;">End Date:</strong>
    <input 
      type="date" 
      class="form-control flex-grow-1" 
      v-model="projectData.endDate" 
    />
  </div>
  
  <!-- Creator -->
  <div class="col-8 d-flex align-items-center mb-3">
    <strong class="me-2" style="width: 150px; text-align: right;">Creator:</strong>
    <Select2 
      :options="allData.users" 
      v-model="projectData.createdBy" 
      :settings="select2SettingsProject" 
      class="flex-grow-1" 
    />
  </div>
  
  <!-- Manager -->
  <div class="col-8 d-flex align-items-center mb-3">
    <strong class="me-2" style="width: 150px; text-align: right;">Manager:</strong>
    <Select2 
      :options="allData.users" 
      v-model="projectData.managerId" 
      :settings="select2SettingsProject" 
      class="flex-grow-1" 
    />
  </div>
  
  <!-- Assigned Users -->
  <div class="col-8 d-flex align-items-center mb-3">
    <strong class="me-2" style="width: 150px; text-align: right;">Assigned Users:</strong>
    <Select2 
      :options="allData.users" 
      v-model="projectData.assignedUsers" 
      :settings="{ ...select2SettingsProject, multiple: true }" 
      class="flex-grow-1" 
    />
  </div>

  <!-- Client -->
  <div class="col-8 d-flex align-items-center mb-3">
    <strong class="me-2" style="width: 150px; text-align: right;">Client:</strong>
    <Select2 
      :options="allData.clients" 
      v-model="projectData.clientId" 
      :settings="select2SettingsProject" 
      class="flex-grow-1" 
    />
  </div>

  <!-- Services -->
  <div class="col-8 d-flex align-items-center mb-3">
    <strong class="me-2" style="width: 150px; text-align: right;">Services:</strong>
    <Select2 
      :options="allData.services" 
      v-model="projectData.serviceId" 
      :settings="select2SettingsProject" 
      class="flex-grow-1" 
    />
  </div>

  <!-- Payable -->
  <div class="col-8 d-flex align-items-center mb-3">
    <strong class="me-2" style="width: 150px; text-align: right;">Payable:</strong>
    <Select2 
      :options="[{ id: '', text: 'Παρακαλώ επιλέξτε' }, { id: '0', text: 'No' }, { id: '1', text: 'Yes' }]" 
      v-model="projectData.payable" 
      :settings="select2SettingsProject" 
      class="flex-grow-1" 
    />
  </div>

  <!-- Pay Manager -->
  <div class="col-8 d-flex align-items-center mb-3">
    <strong class="me-2" style="width: 150px; text-align: right;">Pay Manager:</strong>
    <Select2 
      :options="allData.users" 
      v-model="projectData.payManagerId" 
      :settings="select2SettingsProject" 
      class="flex-grow-1" 
    />
  </div>

  <!-- Invoiced -->
  <div class="col-8 d-flex align-items-center mb-3">
    <strong class="me-2" style="width: 150px; text-align: right;">Invoiced:</strong>
    <Select2 
      :options="[{ id: '', text: 'Παρακαλώ επιλέξτε' }, { id: '0', text: 'No' }, { id: '1', text: 'Yes' }]" 
      v-model="projectData.invoiced" 
      :settings="select2SettingsProject" 
      class="flex-grow-1" 
    />
  </div>

  <!-- Paid -->
  <div class="col-8 d-flex align-items-center mb-3">
    <strong class="me-2" style="width: 150px; text-align: right;">Paid:</strong>
    <Select2 
      :options="[{ id: '', text: 'Παρακαλώ επιλέξτε' }, { id: '0', text: 'No' }, { id: '1', text: 'Yes' }]" 
      v-model="projectData.paid" 
      :settings="select2SettingsProject" 
      class="flex-grow-1" 
    />
  </div>
</div>

                        </div>
                    </div>
                </div>

                <!-- Right Column with Project Details -->
                <div class="col-xl-4 col-lg-5 col-md-3 rightCol" v-if="activeTab == 'tasks'" >

                <div class="card border-0 mb-2">
                                <div
                                    class="card-header mb-5 py-1 px-0 d-sm-flex align-items-center justify-content-between">
                                    <div>
                                        <button type="button" class="ms-0 ms-sm-3 btn"
                                            style="color:var(--secondary-color); font-weight:bold"
                                            @click="createTask" v-if="canCreateTask">
                                            <i class="icofont-plus me-2 fs-6"></i> Create Task
                                        </button>
                                    </div>

                                </div>
                                <div class="filter-section mt-3">
                                    <div class="d-flex align-items-center mb-3 justify-content-center col-md-12">

                                    <input id="main-search-input" type="search" class="form-control"
                                            placeholder="Αναζήτηση..." aria-label="search"
                                            aria-describedby="addon-wrapping" />
                                    </div>

                                <!-- Status Tabs -->
                                 <div>
                                <ul class="nav nav-tabs tab-body-header rounded w-100 mb-2" role="tablist">
                                    <li class="nav-item2">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '0' || filters.statusId == undefined,
                                            'nav-link text-center': filters.statusId != '0' && filters.statusId != undefined,
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '0', page: 1 })"
                                            href="#" role="tab" >
                                            <span style="padding-top:0.5em">All</span>        
                                        </a>
                                    </li>
                                    <li class="nav-item2">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '1',
                                            'nav-link text-center': filters.statusId != '1',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '1', page: 1 })"
                                            href="#" role="tab">
                                            <img src="../assets/images/on_hold.png"
                                                        alt="On Hold" title="On Hold" class="menu-img"
                                                        style="width:2.5em">
                                        </a>
                                    </li>
                                    <li class="nav-item2">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '2',
                                            'nav-link text-center': filters.statusId != '2',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '2', page: 1 })"
                                            href="#" role="tab">
                                            <img src="../assets/images/progress.png"
                                                        alt="In Progress" title="In Progress" class="menu-img"
                                                        style="width:2.5em">
                                        </a>
                                    </li>
                                    <li class="nav-item2">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '3',
                                            'nav-link text-center': filters.statusId != '3',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '3', page: 1 })"
                                            href="#" role="tab">
                                            <img src="../assets/images/done.png" alt="Done"
                                                        title="Done" class="menu-img" style="width:2.5em">
                                        </a>
                                    </li>
                                   
                                    <li class="nav-item2">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '5',
                                            'nav-link text-center': filters.statusId != '5',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '5', page: 1 })"
                                            href="#" role="tab">
                                            <img src="../assets/images/on_going.png"
                                                        alt="On going" title="On going" class="menu-img"
                                                        style="width:2.5em">
                                        </a>
                                    </li>
                                    <li class="nav-item2">
                                        <a :class="{
                                            'nav-link text-center active': filters.statusId == '4',
                                            'nav-link text-center': filters.statusId != '4',
                                        }" @click.prevent="() => updateFilters({ ...filters, statusId: '4', page: 1 })"
                                            href="#" role="tab">
                                            <img src="../assets/images/archived.png"
                                                        alt="Archived" title="Archived" class="menu-img"
                                                        style="width:2.5em">
                                        </a>
                                    </li>
                                </ul>
                                </div>
                            <!-- Date Filters -->
                            <div class="mb-3 d-flex align-items-center">
                                <label for="start-date" class="form-label me-2">Start date:</label>
                                <input type="date" id="start-date" v-model="startDate" class="form-control w-auto" />
                            </div>

                            <div class="mb-3 d-flex align-items-center">
                                <label for="end-date" class="form-label me-2">End date:</label>
                                <input type="date" id="end-date" v-model="endDate" class="form-control w-auto" />
                            </div>
                        </div>
                    </div>
                </div>

    <!-- Create Project-->
    <div class="modal fade" id="create-project-form" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fw-bold" id="createprojectlLabel">Edit Project</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="projectTitle" class="form-label" aria-label="Title"
                            aria-placeholder="Title">Title</label>
                        <input type="text" class="form-control" id="projectTitle" placeholder="Title" name="title"
                            v-model="projectData.description" v-if="projectData">
                    </div>
                    <div class="mb-3" v-if="(projectData && projectData.createdBy == currentUserInfo?.id) || superUser">                       
                        <label for="projectDescriptionTinyMce" class="form-label">Creator's brief</label>
                        <Editor api-key="no-api-key" :init="tinymceInit" v-model="projectData.body"></Editor>
                    </div>
                    <div class="mb-3" v-if="(projectData && currentUserInfo && projectData.managerId == currentUserInfo.id)">
                        <label for="projectManagerBriefTinyMce" class="form-label">Manager's brief</label>
                        <Editor api-key="no-api-key" :init="tinymceInit" v-model="projectData.manager_brief"></Editor>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Client</label>
                        <Select2 :options="allData.clients" v-model="projectData.clientId"
                            :settings="select2SettingsProject" />
                    </div>
                    <div class="mb-3" v-if="superUser || canEditProjectCreator">
                        <label class="form-label">Creator</label>
                        <Select2 :options="allData.users" v-model="projectData.createdBy"
                            :settings="select2SettingsProject" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Status</label>
                        <Select2 :options="allData.statuses" v-model="projectData.statusId"
                            :settings="select2SettingsProject" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Services</label>
                        <Select2 :options="allData.services" v-model="projectData.serviceId"
                            :settings="select2SettingsProject" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Manager</label>
                        <Select2 :options="allData.users" v-model="projectData.managerId"
                            :settings="select2SettingsProject" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Assigned users</label>
                        <Select2 :options="allData.users" v-model="projectData.assignedUsers"
                            :settings="{ ...select2SettingsProject, multiple: true }" />
                    </div>
                    <div class="mb-3 payable-container">
                        <div class="mb-3">
                            <label class="form-label">Payable</label>
                            <Select2
                                :options="[{ id: '', text: 'Παρακαλώ επιλέξτε' }, { id: '0', text: 'No' }, { id: '1', text: 'Yes' }]"
                                v-model="projectData.payable" :settings="select2SettingsProject" />
                        </div>
                        <div class="payable-fields" v-if="projectData.payable == '1'">
                            <div class="mb-3">
                                <label class="form-label">Pay Manager</label>
                                <Select2 :options="allData.users" v-model="projectData.payManagerId"
                                    :settings="select2SettingsProject" />
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Invoiced</label>
                                <Select2
                                    :options="[{ id: '', text: 'Παρακαλώ επιλέξτε' }, { id: '0', text: 'No' }, { id: '1', text: 'Yes' }]"
                                    v-model="projectData.invoiced" :settings="select2SettingsProject" />
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Paid</label>
                                <Select2
                                    :options="[{ id: '', text: 'Παρακαλώ επιλέξτε' }, { id: '0', text: 'No' }, { id: '1', text: 'Yes' }]"
                                    v-model="projectData.paid" :settings="select2SettingsProject" />
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="projectEstimatedHours" class="form-label" aria-label="Estimated hours"
                            aria-placeholder="Estimated hours">Estimated hours</label>
                        <input type="number" class="form-control" id="projectEstimatedHours"
                            placeholder="Estimated hours" aria-label="Estimated hours"
                            aria-placeholder="Estimated hours" name="estimatedHours"
                            v-model="projectData.estimatedTime">
                    </div>
                    <div class="deadline-form">
                        <form>
                            <div class="row g-3 mb-3">
                                <div class="col">
                                    <label for="projectStartDate" class="form-label">Start Date</label>
                                    <input type="date" class="form-control" id="projectStartDate" name="startDate"
                                        v-model="projectData.startDate">
                                </div>
                                <div class="col">
                                    <label for="projectEndDate" class="form-label">End Date</label>
                                    <input type="date" class="form-control" id="projectEndDate" name="endDate"
                                        v-model="projectData.endDate">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div id="create-project-errors"></div>
                </div>
                <div class="modal-footer">
<!--                     <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
 -->                    <button type="button" class="btn btn-secondary" @click.prevent="submitForm">Update</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Files Modal -->
    <div class="modal fade" id="filesModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Project Files</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <iframe v-if="projectViewData && projectViewData.info?.shared_link"
                        :src="projectViewData.info?.shared_link" width="100%" height="600px"></iframe>
                    <p v-else>No shared link available for this project.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="unsavedChangesModal" tabindex="-1" aria-labelledby="unsavedChangesLabel"
                    aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="unsavedChangesLabel">Unsaved Changes</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    You have unsaved changes. Are you sure you want to leave this page?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-danger" @click="confirmLeave">Leave</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="archiveConfirmationModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirm Archive</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure you want to archive this task?
                </div>
                <div class="modal-footer">
<!--                     <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
 -->                    <button type="button" class="btn btn-secondary" @click="confirmArchive">Confirm</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Create Task-->
    <div class="modal fade" id="create-task-form" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title fw-bold">New task</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <input type="hidden" name="id" id="ïd" v-model="taskData.id">
                    <div class="mb-3">
                        <label for="projectTitle" class="form-label" aria-label="Title"
                            aria-placeholder="Title">Title</label>
                        <input type="text" class="form-control" id="projectTitle" placeholder="Title" name="title"
                            v-model="taskData.title">
                    </div>
                    <div class="mb-3" v-if="(taskData && (taskData.createdBy == currentUserInfo?.id)) || superUser || taskData.createdBy == '' || taskData.createdBy == null">
                        <label class="form-label">Creator's brief</label>
                        <Editor api-key="no-api-key" :init="tinymceInit" v-model="taskData.body"></Editor>
                    </div>
                    <div class="mb-3" v-if="(taskData && currentUserInfo && taskData.managerId == currentUserInfo.id)">
                        <label class="form-label">Manager's brief</label>
                        <Editor api-key="no-api-key" :init="tinymceInit" v-model="taskData.manager_brief"></Editor>
                    </div>
                    <div class="mb-3" v-if="superUser || canEditTaskCreator">
                        <label class="form-label">Creator</label>
                        <Select2 :options="allData.users" v-model="taskData.createdBy"
                            :settings="select2SettingsProject" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Status</label>
                        <Select2 :options="allData.statuses" v-model="taskData.statusId"
                            :settings="select2SettingsTask" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Manager</label>
                        <Select2 :options="allData.users" v-model="taskData.managerId"
                            :settings="select2SettingsTask" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Assigned users</label>
                        <Select2 :options="allData.users" v-model="taskData.assignedUsers"
                            :settings="{ ...select2SettingsTask, multiple: true }" />
                    </div>
                    <div class="mb-3">
                        <label for="projectEstimatedHours" class="form-label" aria-label="Estimated hours"
                            aria-placeholder="Estimated hours">Estimated hours</label>
                        <input type="number" class="form-control" id="projectEstimatedHours"
                            placeholder="Estimated hours" aria-label="Estimated hours"
                            aria-placeholder="Estimated hours" name="totalHours" v-model="taskData.totalHours">
                    </div>
                    <div class="deadline-form">
                        <form>
                            <div class="row g-3 mb-3">
                                <div class="col">
                                    <label for="projectStartDate" class="form-label">Start Date</label>
                                    <input type="date" class="form-control" id="projectStartDate" name="startDate"
                                        v-model="taskData.startDate">
                                </div>
                                <div class="col">
                                    <label for="projectEndDate" class="form-label">End Date</label>
                                    <input type="date" class="form-control" id="projectEndDate" name="endDate"
                                        v-model="taskData.endDate">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div id="create-project-errors"></div>
                </div>
                <div class="modal-footer">
<!--                     <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
 -->                    <button type="button" class="btn btn-secondary" @click.prevent="submitTaskForm">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
        </div>
    </div>
</template>

<script>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchUserInfo } from '@/services/user';
import { get as getProject } from "@/services/projects";
import { createOrUpdate as createOrUpdateProject, updateInfo, updateManager, updateGeneral } from '@/services/projects';
import { createOrUpdate as createOrUpdateTask } from '@/services/tasks';
import { useToast } from 'vue-toastification';
import { getClientsServicesStatusesUsers } from '@/services/projects';
import { fetchAll } from '@/services/tasks';
import Editor from '@tinymce/tinymce-vue';
import Select2 from 'vue3-select2-component';
import { get as getTask } from '@/services/tasks';
import { mapGetters } from 'vuex';
import { updateTaskStatus } from '@/services/followup';
import { massArchiveTasks } from '@/services/tasks';
import { updateProjectStatus } from '@/services/todo';
import { createComment } from '@/services/projects';
import { fetchManagers } from '@/services/followup';


export default {
    name: 'Projects',
    components: {
        Editor,
        Select2
    },
    data() {
        return {
            showUnsavedModal: false,
            pendingNavigation: null, // Store pending navigation details
            newComment: '',
            showAutocomplete: false,
            filteredUsernames: [],
            isEditingInfo: false,
            isEditingGeneral: false,
            isEditingManagersBrief: false,
            projectViewData: {
            client: {
                nickname: null, // Default to null or an empty string
            },
        },    };
    },
    methods: {
        confirmLeave() {
            this.resetEditingStates();
            if (this.pendingNavigation) {
                this.pendingNavigation();
                this.pendingNavigation = null;
            }
            const modal = $('#unsavedChangesModal');
            modal.modal('hide');
        },
        resetEditingStates() {
            this.isEditingInfo = false;
            this.isEditingManagersBrief = false;
            this.isEditingGeneral = false;
        },
        canEditProject(project) {
            if (!project) return false;
            return localStorage.getItem('current_user_id') == project.info?.createdBy && this.userPermissionsProjects?.edit === 1;
        },
        editProject() {
            this.isEditingInfo = true;
        },
        editProjectManager() {
            this.isEditingManagersBrief = true;
        },

        cancelEditing() {
            this.isEditingInfo = false;

        },
        cancelEditingManager() {
            this.isEditingManagersBrief = false;

        },
        canEditTask(task) {
            if (!task) return false;
            return localStorage.getItem('current_user_id') == task.info?.createdBy && this.userPermissionsTasks?.edit === 1;
        },

        async saveInfo() {
            try {
                // Prepare the payload with both `id` and `body`
                const payload = {
                    id: this.$route.params.id, // ID from route parameters
                    body: this.projectViewData.info.body // Editor content
                };

                const toast = useToast();


                // Call the updateInfo service with the correct payload
                const response = await updateInfo(this.projectViewData.info);

                // Show success message
                toast.success(response.data.message);

                // Exit editing mode
                this.isEditingInfo = false;
            } catch (error) {
                // Handle any errors that occur during the API call
                console.error('Error updating information:', error);
            }
        },

        async saveManager() {
            try {
                // Prepare the payload with both `id` and `body`
                const payload = {
                    id: this.$route.params.id, // ID from route parameters
                    manager_brief: this.projectViewData.info.manager_brief // Editor content
                };

                const toast = useToast();


                // Call the updateInfo service with the correct payload
                const response = await updateManager(this.projectViewData.info);

                // Show success message
                toast.success(response.data.message);

                // Exit editing mode
                this.isEditingManagersBrief = false;
            } catch (error) {
                // Handle any errors that occur during the API call
                console.error('Error updating information:', error);
            }
        },
        scrollToEnd() {
            this.$nextTick(() => {
                const chatHistory = this.$refs.chatHistory;
                if (chatHistory) {
                    chatHistory.scrollTop = chatHistory.scrollHeight;
                }
            });
        },
        
        submitComment() {
            // Your submit comment logic here
            this.scrollToEnd(); // Scroll to end after adding new comment
        },
        async fetchManagersData() {
            try {
                const response = await fetchManagers();
                if (response.data.success) {
                    this.managers = response.data.data;
                }
            } catch (error) {
                console.error('Failed to fetch managers:', error);
            }
        },
        async fetchCreatorsData() {
            try {
                const response = await fetchManagers();
                if (response.data.success) {
                    this.creators = response.data.data;
                } else {
                    console.error("Failed to fetch creators:", response.data.message);
                }
            } catch (error) {
                console.error("Failed to fetch creators:", error);
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.isEditingInfo || this.isEditingManagersBrief || this.isEditingGeneral) {
            this.pendingNavigation = next;
            const modal = $('#unsavedChangesModal');
            modal.modal('show');
        } else {
            next();
        }
    },
    mounted() {
        // Initial scroll to end after the component mounts
        this.scrollToEnd();
        this.fetchManagersData();
        this.fetchCreatorsData();
    },
    watch: {
        'projectViewData.comments': function(newComments) {
            if (newComments && newComments.length) {
                // Scroll to the end when comments are loaded or updated
                this.scrollToEnd();
            }
        }
    },
    computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission'
        }),
        userPermissionsTasks() {
            return this.getUserPermission('Tasks');
        },
        userPermissionsProjects() {
            return this.getUserPermission('Projects');
        },
        canEditProjectCreator() {
            return this.userPermissionsProjects?.editCreator === 1;
        },  
        canEditTaskCreator() {
            return this.userPermissionsTasks?.editCreator === 1;
        },
        canCreate() {
            return this.userPermissionsTasks?.create === 1;
        },
        canUpdate() {
            return this.userPermissionsTasks?.create === 1;
        },
        canArchive() {
            return this.userPermissionsTasks?.archive === 1;
        },
        canCreateTask() {
            return this.userPermissionsTasks?.create === 1;
        },
        canArchiveTask() {
            return this.userPermissionsTasks?.archive === 1;
        },
        canEditAllProjects() {
            return this.userPermissionsProjects?.editAll === 1;
        },
        canEditAllTasks() {
            return this.userPermissionsTasks?.editAll === 1;
        },
        superUser() {
            return this.getSuperUserPermission === 1;
        },
        getUser() {
            return this.$store.state.userInfo.id;
        },
        currentUserId() {
            return localStorage.getItem('current_user_id'); // Safely retrieve the ID once
        }
    },
    setup() {
        const activeTab = ref('info');
        const allData = ref({ users: [], clients: [], statuses: [], services: [] });
        const newComment = ref('');
        const route = useRoute();
        const router = useRouter();
        const toast = useToast();
        const currentUserInfo = ref(null);
        const projectViewData = ref(null);
        const selectedTaskIds = ref([]);
        const startDate = ref('');
        const endDate = ref('');
        const taskIdToArchive = ref(null);
        const filteredUsernames = ref([]);
        const showAutocomplete = ref(false);
        const users = ref([]);
        const managers = ref([]);
        const creators = ref([]);
        const isEditingGeneral = ref(false); // Ensure this is defined as a ref


        // Watch for changes to startDate and endDate and update URL filters
        watch([startDate, endDate], ([newStartDate, newEndDate]) => {
            router.push({
                path: '/project',
                query: { ...route.query, startDate: newStartDate, endDate: newEndDate },
            });
        });
        
        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { id: route.query.id, page: route.query.page || 1, archived: route.query.archived || 0 }, { id: route.query.id, page: 1, archived: 0 });

        const transformDate = (dateStr) => {
            const [day, month, year] = dateStr.split('/');
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate;
        };

        const revertDate = (dateStr) => {
            const [year, month, day] = dateStr.split('-');
            const formattedDate = `${day}/${month}/${year}`;
            return formattedDate;
        };

        const openArchiveConfirmation = (taskId) => {
            taskIdToArchive.value = taskId;
            $('#archiveConfirmationModal').modal('show');
        };

        const confirmArchive = async () => {
            if (taskIdToArchive.value !== null) {
                const response = await massArchiveTasks({ ids: [taskIdToArchive.value] });
                if (response.data.success) {
                    fetchData();
                    useToast().success(response.data.message);
                } else {
                    useToast().error(response.data.message);
                }
                taskIdToArchive.value = null;
                $('#archiveConfirmationModal').modal('hide');
            }
        };

        const defaultProjectData = {
            title: '',
            description: '',
            manager_brief: '',
            clientId: '',
            assignedUsers: [],
            serviceId: '',
            managerId: '',
            statusId: '',
            payable: '0',
            payManagerId: '',
            invoiced: '0',
            paid: '1',
            estimatedTime: '',
            startDate: '',
            endDate: ''
        };

        const projectData = ref(defaultProjectData);

        const defaultTaskData = {
            projectId: route.query.id || 0,
            title: '',
            body: '',
            manager_brief: '',
            assignedUsers: [],
            managerId: '',
            statusId: '',
            totalHours: '',
            startDate: '',
            endDate: ''
        };

        
      
        const taskData = ref(defaultTaskData);

       /*  const editProject = async (id) => {
            const tmpAllData = await getClientsServicesStatusesUsers({params: {type: "projects"}});
            
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            const projectInfo = await getProject({ "id": id });
            if (projectInfo.data.success) {
                projectData.value = {
                    id: projectInfo.data.data.info.id,
                    projectId: route.query.id || 0,
                    createdBy: projectInfo.data.data.creator?.id,
                    description: projectInfo.data.data.info.description,
                    body: projectInfo.data.data.info.body,
                    manager_brief: projectInfo.data.data.info.manager_brief,
                    assignedUsers: projectInfo.data.data.assigned ? projectInfo.data.data.assigned?.map((assignedUser) => assignedUser.id) : [],
                    managerId: projectInfo.data.data.manager?.id,
                    serviceId: projectInfo.data.data.service?.id,
                    clientId: projectInfo.data.data.client?.id,
                    payable: projectInfo.data.data.info.payable || '0',
                    payManagerId: projectInfo.data.data.payManager?.id,
                    invoiced: projectInfo.data.data.info.invoiced || '0',
                    paid: projectInfo.data.data.info.paid || '0',
                    statusId: projectInfo.data.data.status?.id,
                    estimatedTime: projectInfo.data.data.info.estimatedTime,
                    startDate: transformDate(projectInfo.data.data.info.startDate),
                    endDate: transformDate(projectInfo.data.data.info.endDate)
                };
            }
            $("#create-project-form").modal('toggle');
        }; */

        const saveGeneral = async () => {
            console.log(projectData.value)
            const response = await updateGeneral(projectData.value);
            response.data.success ? toast.success(response.data.message) : toast.error(response.data.message);
            projectViewData.value = (await getProject({ id: route.query.id })).data.data;
            fetchData();
            isEditingGeneral.value = false;
        };

        const editProjectGeneral = async () => {
            try {
                const tmpAllData = await getClientsServicesStatusesUsers({ params: { type: "subtasks" } });
                let newData = { users: [], statuses: [], clients: [], services: [] };

                for (let i = 0; i < tmpAllData.data.users.length; i++) {
                    newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
                }
                for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                    newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
                }
                for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                    newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
                }
                for (let i = 0; i < tmpAllData.data.services.length; i++) {
                    newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
                }

                allData.value = newData; // `allData` is a ref, so use `.value`

                const projectInfo = await getProject({ id: route.query.id  });
            if (projectInfo.data.success) {
                projectData.value = {
                    id: projectInfo.data.data.info.id,
                    projectId: route.query.id || 0,
                    createdBy: projectInfo.data.data.creator?.id,
                    description: projectInfo.data.data.info.description,
                    body: projectInfo.data.data.info.body,
                    manager_brief: projectInfo.data.data.info.manager_brief,
                    assignedUsers: projectInfo.data.data.assigned ? projectInfo.data.data.assigned?.map((assignedUser) => assignedUser.id) : [],
                    managerId: projectInfo.data.data.manager?.id,
                    serviceId: projectInfo.data.data.service?.id,
                    clientId: projectInfo.data.data.client?.id,
                    payable: projectInfo.data.data.info.payable || '0',
                    payManagerId: projectInfo.data.data.payManager?.id,
                    invoiced: projectInfo.data.data.info.invoiced || '0',
                    paid: projectInfo.data.data.info.paid || '0',
                    statusId: projectInfo.data.data.status?.id,
                    estimatedTime: projectInfo.data.data.info.estimatedTime,
                    startDate: transformDate(projectInfo.data.data.info.startDate),
                    endDate: transformDate(projectInfo.data.data.info.endDate)
                };
            }

            } catch (error) {
                console.error('Error in editProjectGeneral:', error);
            }

            isEditingGeneral.value = true; // Use `.value` for reactive refs
        };

        const cancelEditingGeneral = async () => {
            isEditingGeneral.value = false;

        };

        const editTask = async (id) => {
            const tmpAllData = await getClientsServicesStatusesUsers({params: {type: "tasks"}});
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            const taskInfo = await getTask({ "id": id });
            if (taskInfo.data.success) {
                taskData.value = {
                    id: taskInfo.data.data.info.id,
                    projectId: route.query.id || 0,
                    createdBy: taskInfo.data.data.creator?.id,
                    title: taskInfo.data.data.info.title,
                    body: taskInfo.data.data.info.body,
                    manager_brief: taskInfo.data.data.info.manager_brief,
                    assignedUsers: taskInfo.data.data.assigned ? taskInfo.data.data.assigned?.map((assignedUser) => assignedUser.pivot.userId) : [],
                    managerId: taskInfo.data.data.manager?.id,
                    statusId: taskInfo.data.data.status?.id,
                    totalHours: taskInfo.data.data.info.totalHours,
                    startDate: taskInfo.data.data.info.startDate,
                    endDate: taskInfo.data.data.info.endDate
                };
            }
            $("#create-task-form").modal('toggle');
        };

        const archiveTask = async (taskId) => {
            const response = await massArchiveTasks({ ids: [taskId] });
            if (response.data.success) {
                fetchData();
                useToast().success(response.data.message);
            } else {
                useToast().error(response.data.message);
            }
        };

        const submitComment = async () => {
            const response = await createComment({"id": route.query?.id, comment: newComment.value});
            if (response.data.success) {
                newComment.value = "";
                const projectDataTmp = await getProject({id: route.query?.id || 0});
                if (projectDataTmp.data.success) {
                    projectViewData.value = projectDataTmp.data.data;
                }
                $(".chat-history").animate({ scrollTop: $(".chat-history")[0].scrollHeight }, 1000);
                useToast().success(response.data.message);
            } else {
                useToast().error(response.data.message);
            }
        };

        // Update task status
        const updateTaskStatusId = async (task, statusId) => {
            const response = await updateTaskStatus(task.id, statusId);
            if (response.data.success) {
                fetchData();
                useToast().success(response.data.message);
            } else {
                useToast().error(response.data.message);
            }
            // Optionally, make an API call here to save the updated task status
        };
        const updateManagerFilter = (event) => {
            const newManagerId = event.target.value;
            updateFilters({ ...filters.value, managerFilter: newManagerId, page: 1 });
        };

        const submitTaskForm = async () => {
            const response = await createOrUpdateTask(taskData.value);
            if (response.data.success) {
                fetchData();
                useToast().success(response.data.message);
                $("#create-task-form").modal('toggle');
            } else {
                useToast().error(response.data.message);
            }
        };

        const statuses = ref([]);
        const task_statuses = ref([]);


        const getStatusIcon = (id) => {
            const status = statuses.value.find((s) => s.id === id);
            return status ? status.icon : "";
        };

        const getCurrentStatus = (id) => {
            const status = statuses.value.find((s) => s.id === id);
            return status ? status.description : "Select Status";
        };

        const createTask = async () => {
            const tmpAllData = await getClientsServicesStatusesUsers({params: {type: "projects"}});
            let newData = { users: [], statuses: [], clients: [], services: [] };
            for (let i = 0; i < tmpAllData.data.users.length; i++) {
                newData.users.push({ id: tmpAllData.data.users[i].id, text: tmpAllData.data.users[i].username });
            }
            for (let i = 0; i < tmpAllData.data.statuses.length; i++) {
                newData.statuses.push({ id: tmpAllData.data.statuses[i].id, text: tmpAllData.data.statuses[i].description });
            }
            for (let i = 0; i < tmpAllData.data.clients.length; i++) {
                newData.clients.push({ id: tmpAllData.data.clients[i].id, text: tmpAllData.data.clients[i].title });
            }
            for (let i = 0; i < tmpAllData.data.services.length; i++) {
                newData.services.push({ id: tmpAllData.data.services[i].id, text: tmpAllData.data.services[i].title });
            }
            allData.value = newData;
            taskData.value = {
                id: '',
                projectId: route.query.id || 0,
                title: '',
                manager_brief: '',
                body: '',
                assignedUsers: [],
                managerId: '',
                statusId: '',
                totalHours: '',
                startDate: '',
                endDate: ''
            };
            $("#create-task-form").modal('toggle');
        };

        const getStatusStyle = (id) => {
            switch (id) {
                case 2:
                    return 'background: #1CA6F1; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em; font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; align-items:center; height:2.5em';
                case 3:
                    return 'background: #A5CF27; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center;  height:2.5em';
                case 1:
                    return 'background: #B1B1B1; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:2.5em';
                case 5:
                    return 'background: #ECC308; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:2.5em';
                case 4:
                    return 'background: #796EEE; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:2.5em';
                case 6:
                    return 'background: #E4364D; color:white; padding: 2px 8px; border-radius: 99px; border:none; width:10em;  font-size: smaller; font-weight: bold; white-space: nowrap; text-align:center; height:2.5em';
                default:
                    return 'text-align:center';
            }
        };

        const submitForm = async () => {
            const response = await createOrUpdateProject(projectData.value);
            if (response.data.success) {
                const projectDataTmp = await getProject({ id: route.query.id || 0 });
                if (projectDataTmp.data.success) {
                    projectViewData.value = projectDataTmp.data.data;
                }
                fetchData();
                const projectInfo = await getProject({ "id": route.query.id || 0 });
                if (projectInfo.data.success) {
                    projectData.value = {
                        id: projectInfo.data.data.info.id,
                        projectId: route.query.id || 0,
                        description: projectInfo.data.data.info.description,
                        body: projectInfo.data.data.info.body,
                        manager_brief: projectInfo.data.data.info.manager_brief,
                        assignedUsers: projectInfo.data.data.assigned ? projectInfo.data.data.assigned?.map((assignedUser) => assignedUser.id) : [],
                        managerId: projectInfo.data.data.manager?.id,
                        serviceId: projectInfo.data.data.service?.id,
                        clientId: projectInfo.data.data.client?.id,
                        payable: projectInfo.data.data.info.payable || '0',
                        payManagerId: projectInfo.data.data.payManager?.id,
                        invoiced: projectInfo.data.data.invoiced || '0',
                        paid: projectInfo.data.data.paid || '0',
                        statusId: projectInfo.data.data.status?.id,
                        estimatedTime: projectInfo.data.data.info.estimatedTime,
                        startDate: transformDate(projectInfo.data.data.info.startDate),
                        endDate: transformDate(projectInfo.data.data.info.endDate)
                    };
                }
                useToast().success(response.data.message);
                $("#create-project-form").modal('toggle');
            } else {
                useToast().error(response.data.message);
            }
        };


        const selectStatus = async (projectId, statusId) => {
            const r = await updateProjectStatus(projectId, statusId);
            if (r.data.success) {
                useToast().success(r.data.message);
                const projectDataTmp = await getProject({ id: route.query.id || 0 });
                if (projectDataTmp.data.success) {
                    projectViewData.value = projectDataTmp.data.data;
                }
            } else {
                useToast().error(r.data.message);
            }
        };
        
        const searchAction = () => {
            setTimeout(() => {
                router.push({ path: '/project', query: { ...route.query, search: $("#main-search-input").val() } });
            }, 300);
        };

        onMounted(async () => {
            const allRequiredData = await getClientsServicesStatusesUsers({params: {type: "projects"}});
            const allRequiredDataTasks = await getClientsServicesStatusesUsers({params: {type: "tasks"}});

            statuses.value = allRequiredData.data.statuses;
            task_statuses.value = allRequiredDataTasks.data.statuses;
            users.value = allRequiredData.data.users;
            const userInfo = await fetchUserInfo();
            if (userInfo.data) {
                currentUserInfo.value = userInfo.data.info;
            }
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
            const projectDataTmp = await getProject({ id: route.query.id || 0 });
            if (projectDataTmp.data.success) {
                projectViewData.value = projectDataTmp.data.data;
            }
            document.addEventListener('click', handleClickOutside);

        });

        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const selectUsername = (username) => {
            newComment.value = newComment.value.replace(/@(\w*)$/, `@${username} `);
            filteredUsernames.value = [];
            showAutocomplete.value = false;
        };

        

        const dropdownTop = ref(0);
const dropdownLeft = ref(0);
const dropdownWidth = ref(0);

const filterUsernames = () => {
            const input = newComment.value;
            const match = input.match(/@(\w*)$/); // Match @username pattern

            if (match) {
                const searchTerm = match[0].substring(1); // Extract text after '@'

                  // Call the filtering logic only if there are at least 2 characters after "@"
                if (searchTerm.length >= 2) {

                const query = match[1].toLowerCase();
                filteredUsernames.value = users.value.filter(user =>
                    user.username.toLowerCase().includes(query)
                );
                showAutocomplete.value = filteredUsernames.value.length > 0;
                }else {
                showAutocomplete.value = false;
            }
            } else {
                showAutocomplete.value = false;
            }
        };

        const handleClickOutside = (event) => {
    const dropdown = document.querySelector('.autocomplete-dropdown');
    const textarea = document.getElementById('commentTextarea');

    if (
        (dropdown && !dropdown.contains(event.target)) &&
        (!textarea || !textarea.contains(event.target))
    ) {
        showAutocomplete.value = false;
    }
};



onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});

        return {
            currentUserInfo,
            users,
            showAutocomplete,
            selectUsername,
            filterUsernames,
            filteredUsernames,
            createTask,
            getPageFromUrl,
            paginationLinks,
            openArchiveConfirmation,
            confirmArchive,
            startDate,
            endDate,
            filters,
            updateFilters,
            archiveTask,
            editTask,
            submitForm,
            submitTaskForm,
            updateTaskStatusId,
            selectedTaskIds,
            sortBy,
            data,
            revertDate,
            transformDate,
            searchAction,
            taskData,
            projectData,
            allData,
            projectViewData,
            route,
            activeTab,
            newComment,
            statuses,
            task_statuses,
            submitComment,
            getStatusIcon,
            getCurrentStatus,
            selectStatus,
            getStatusStyle,
            updateManagerFilter,
            editProjectGeneral,
            isEditingGeneral,
            cancelEditingGeneral,
            saveGeneral
        };
    }
};
</script>

<style scoped>

.chat-message {
    color: black !important;
}
.project-code {
    font-size: 1.5rem;
    font-weight: bold;
}

.status-dropdown {
    position: relative;
    display: inline-block;
}

.status-button {
    background-color: #f1f1f1;
    color: #333;
    padding: 8px 16px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
   
}

.status-dropdown:hover .status-dropdown-content {
    display: flex;
}

.status-dropdown-content {
    z-index: 9000;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 8px;
    display: none;
    flex-direction: column;
    
}

.status-dropdown >button{
    height:1.5em;
}

.status-dropdown-content button {
    width: 100%;
    background: none;
    border: none;
    padding: 10px;
    text-align: left;
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom:3px;

}

.status-dropdown-content button:hover {
    background-color: #461010;
}

.active-status {
    font-weight: bold;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
}

.create-task-button {
    width: 100%;
}


.nav-tabs{
    border:none;
}

/* Style for all list items to have a bottom border */
.nav-tabs .nav-item {
  border-bottom: 1px solid #DCDCDC; /* Light gray border for non-active items */
  margin-left:20px;
}

.nav-tabs .nav-item .nav-link {
  color:#8C8C8C;
}

/* Style for the active tab to have a darker bottom border */
.nav-tabs .nav-item .nav-link.active,  .nav-tabs .nav-item .nav-link:hover{
    border-color: white;

  border-bottom: 3px solid #8C8C8C; /* Darker gray border for the active tab */
  color:black;

}

/* Optionally, you can remove the bottom border for non-active tabs on hover to make the UI clearer */

/* Add this CSS to ensure the button is placed on the right border */
.col-xl-8.col-lg-7.col-md-12 {
  position: relative; /* Make the parent div relative */
}

/* Style the button to be positioned absolutely at the border-right */
.status-button-wrapper {
  position: absolute; /* Position it absolutely within the parent */
  top: 50px; /* Vertically center the button */
  right: -1.5em; /* Position it slightly outside to touch the border-right */
  transform: translateY(-50%); /* Adjust for exact vertical centering */
  z-index: 10; /* Ensure the button stays above other elements */
}

.autocomplete-dropdown {
    position: absolute;
    z-index: 1000;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 200px; /* Limit height */
    overflow-y: auto;
}

.autocomplete-dropdown ul {
    padding: 0;
    margin-top: 110px;
}

.autocomplete-item {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.autocomplete-item:hover {
    background-color: #f0f0f0;
    color: #333;
}

textarea {
    resize: none;
    overflow: auto;
}

textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}


</style>
