<template>
    <div class="container-xxl">
      <div class="row align-items-center">
        <div class="border-0">
          <div class="card-header py-1 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between flex-wrap">
            <div class="d-flex py-2 project-tab flex-wrap justify-content-between gap-2">
              <div class="d-flex">
                <div class="d-flex w-100 text-nowrap align-items-center gap-3">
                  <label for="filterDateMonth" class="form-label mb-0">Επιλέξτε μήνα:</label>
                  <select v-model="filterMonth" id="filterDateMonth" class="form-select" aria-label="Επιλέξτε μήνα:">
                    <option value="">Παρακαλώ επιλέξτε</option>
                    <option value="01">Ιανουάριος</option>
                    <option value="02">Φεβρουάριος</option>
                    <option value="03">Μάρτιος</option>
                    <option value="04">Απρίλιος</option>
                    <option value="05">Μάϊος</option>
                    <option value="06">Ιούνιος</option>
                    <option value="07">Ιούλιος</option>
                    <option value="08">Αύγουστος</option>
                    <option value="09">Σεπτέμβρης</option>
                    <option value="10">Οκτώβρης</option>
                    <option value="11">Νοέμβρης</option>
                    <option value="12">Δεκέμβρης</option>
                  </select>
                  
                </div>
              </div>
              <div class="d-flex">
                <div class="d-flex w-100 text-nowrap align-items-center gap-3">
                  <label for="filterDateYear" class="form-label mb-0">Επιλέξτε έτος:</label>
                  <select v-model="filterYear" id="filterDateYear" class="form-select" aria-label="Επιλέξτε έτος:">
                    <option v-for="year in years" :value="year.value" :selected="year.selected">{{ year.value }}</option>
                  </select>
                </div>
              </div>
              <div class="col-12 mt-3 mt-xl-0 col-xl-2">
            <button type="button" class="w-sm-100 ms-0 btn btn-secondary" @click="doSearch"><i class="icofont-search me-2 fs-6"></i>Αναζήτηση</button>
          </div>
            </div>
          </div>
        </div>
       <!--  <div class="d-flex py-2 project-tab flex-wrap gap-0 gap-xl-2 col-12">
          <div class="col-5 col-xl-2">
            <Select2 :options="users || []" v-model="selectedUser" :settings="select2Settings" />
          </div>
          
        </div> -->
      </div>
      <div class="row clearfix g-3">
        <div class="col-lg-12">
          <div>
            <div>
              <div class="card mb-2">
                <div class="card-body p-0 mt-3 table-responsive">
                  <table id="reports" class="table table-hover align-middle mb-0 mis_table" style="width:100%">
                    <thead>
                      <tr>
                        <th style="width:10%;" v-if="superUser || canViewAllDaysOff">
                      <div  class="d-flex align-items-center">
                        <select class="ms-1 nav-link rounded" name="" id="dropdownManagers"
                          @change="updateManagerFilter">
                          <option value="" selected>USER</option>
                          <option v-for="manager in managers" :key="manager.id"
                              :value="manager.id">
                              {{ manager.username }}
                          </option>
                        </select>
                        <img src="../assets/images/icons8-superman.svg" alt="Citrine Logo" class="menu-img ms-2" style="width:2em">

                      </div>
                      
                    </th>
                        <th  v-else>User</th>
                        <th style="width:6%;"><div>Total</div></th>
                        <th style="width:8%;"><div>Remaining</div></th>

                        <th style="width:6%;" v-for="month in months" :key="month"><div>{{ month }}</div></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="report in reports" :key="report.userId">
                        <td >{{ report.username }}</td>
                        <td>{{ report.total_days_off_count }}</td>
                        <td>{{ report.remaining_days_off_count }}</td>
                        <td v-for="month in report.months" :key="month.month">{{ month.count }}</td>
                      </tr>
                      <tr class="col" v-if="!reports || !reports.length">
                            <td>No data</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>                         
      </div>
    </div>
  </template>
  
  <script>
  import apiClient from '../services/api';
  import Select2 from 'vue3-select2-component';
  import { fetchManagers } from '@/services/followup';
  import { mapGetters } from 'vuex';
import { ref, onMounted, computed } from 'vue';


  export default {
    name: 'Days Off Reports',
    components: {
        Select2
    },
    computed: {
        ...mapGetters({
            getUserPermission: 'getUserPermission',
            getSuperUserPermission: 'getSuperUserPermission',

        }),
        superUser() {
            return this.getSuperUserPermission === 1;
        },
        userPermissions() {
            return this.getUserPermission('Days off reports');
        },
        canEditDayOff() {
            return this.userPermissions?.editCreator === 1;
        },
        canEditAllDaysOff() {
            return this.userPermissions?.editAll === 1;
        },
        canViewAllDaysOff() {
            return this.userPermissions?.viewAdmin === 1;
        },
        canCreateDayOff() {
            return this.userPermissions?.create === 1;
        },
        canArchive() {
            return this.userPermissions?.archive === 1;
        },
    },
    data() {
      return {
        filterYear: new Date().getFullYear(),
        filterMonth: '',
        select2Settings: {
              allowClear: true,
              placeholder: "Please select"
        },
        selectedUser: null,
        users: [],
        selectedManagerId: null,
        managers: [],
        reports: [],
        months: [],
        years: [
          { value: (new Date().getFullYear() - 1), selected: '' },
          { value: new Date().getFullYear(), selected: 'selected="selected"' }
        ]
      };
    },
    methods: {
      async fetchManagersData() {
      try {
        const response = await fetchManagers();
        if (response.data.success) {
          this.managers = response.data.data;
        }
      } catch (error) {
        console.error('Failed to fetch managers:', error);
      }
    },
      fetchData(year, month, search, userId) {
        apiClient.get('get-days-off-report', {
          headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('api_token')}`
          },
          params: {
            year: year || this.filterYear,
            month: month || this.filterMonth,
            userId: userId || this.selectedUser,
            search: search || $("#main-search-input").val()
          }
        })
        .then(response => {
          this.reports = response.data.data.data || [];
          this.months = response.data.data.months || [];
          let tmpUsers = [];
          for (let i = 0; i < response.data.data.users.length; i++) {
              tmpUsers.push({ id: response.data.data.users[i].id, text: response.data.data.users[i].username });
          }
          this.users = tmpUsers;
        })
        .catch(error => {
          console.error(`status code: ${error.response.status}  jqXHR.responseText: ${error.response.data}`);
        });
      },
      doSearch() {
      this.fetchData(this.filterYear, this.filterMonth, $("#main-search-input").val(),  this.selectedManagerId);
    },
      updateManagerFilter(event) {
      this.selectedManagerId = event.target.value;
      this.doSearch();
    },
      applyServicesBindings() {
        $(document.body).on('click', '[data-selection-id]', function() {
          var selectionId = $(this).attr('data-selection-id');
          if ($(`[data-project-id-tasks="${selectionId}"]`).length > 1) {
            $(`[data-project-id-tasks="${selectionId}"]`).not('.reports_tasks_empty').each(function() {
              $(this).toggleClass('selectedRow');
              if ($(this).css('display') == 'none') {
                $(this).css('display', '');
              } else {
                $(this).css('display', 'none');
              }
            });
          } else {
            $(`[data-project-id-tasks="${selectionId}"]`).each(function() {
              $(this).toggleClass('selectedRow');
              if ($(this).css('display') == 'none') {
                $(this).css('display', '');
              } else {
                $(this).css('display', 'none');
              }
            });
          }
        });
  
        $(document.body).on('click', '.do-search', this.doSearch);
  
        $(document.body).on('keyup', '#main-search-input', () => {
          window.MIS.searchTimeout = window.MIS.searchTimeout || false;
          if (window.MIS.searchTimeout !== false) {
            clearTimeout(window.MIS.searchTimeout);
          }
          window.MIS.searchTimeout = setTimeout(this.doSearch, 500);
        });
  
        $(document.body).on('click', '#main-search-input', () => {
          window.MIS.searchTimeout = window.MIS.searchTimeout || false;
          if (window.MIS.searchTimeout !== false) {
            clearTimeout(window.MIS.searchTimeout);
          }
          window.MIS.searchTimeout = setTimeout(this.doSearch, 500);
        });
      }
    },
    async mounted() {
      this.applyServicesBindings();
      this.fetchData();
      this.fetchManagersData();

    }
  };
  </script>
  
  <style scoped>
  /* Add any component-specific styles here */
  </style>
  